<template>
  <section class="relative">


    <div class="max-w-6xl mx-auto px-4 sm:px-6">

      <!-- Hero content -->
      <div class="pt-32 pb-8 md:pt-40 md:pb-10 md:flex md:justify-between" 
      container>

        <div class="text-right pb-12 md:16 md:flex-grow pr-6">

            <img 
            class="relative" 
            :src="require('@/images/text-classification.gif')" 
            width="1100" alt="Author 01" />

        </div>

        <!-- Section header -->
        <div class="text-left my-6 md:my-0 md:flex-shrink-1">
            <div>
                <h1 class="h1 text-5xl md:text-5xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">
                    
                    <span class="bg-clip-text text-blue-500">
                    Text Classification API
                    </span>
                </h1>
                <div class="max-w-0xl mx-auto">
                    <p class="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">
                    Classify text data to specific categories such as 
                    shipping status, service type, product quality and etc.
                    Metatext allows anyone to create custom text classification models or use built-in models, and integrate on your workflow.
                    </p>
                </div>
            </div>
        </div>

      </div>

    </div>
    <div class="max-w-6xl mx-auto px-4 sm:px-6 pb-12">
      <div class="py-12 md:py-20 border-t border-gray-200">

        <div class="max-w-lg mx-auto pb-24">
              <form>
                <div class="flex flex-wrap -mx-3 mb-4">
                  <div class="w-full px-3">
                      <label class="block text-gray-800 text-sm font-medium mb-1" for="name">
                        Query text, select an example
                      </label>
                    <select :v-for="string in query_options" id="name" row="10" class="form-input w-full text-gray-800" placeholder="Enter your name" required >
                        <option :value="string">{{ string }}</option>
                    </select>
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-4">
                  <div class="w-full px-3">
                    <textarea id="name" row="10" class="form-input w-full text-gray-800" 
                    placeholder="Query text" required />
                  </div>
                  <div class="w-full px-3">
                    <button class="btn text-white bg-blue-600 hover:bg-blue-700 w-full">Sign up</button>
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-4">
                  
                  <div class="w-full px-3">
                    <b>RESULT</b><br>
                    Input text above or try analyzer with our example
                  </div>
                </div>
              </form>
        </div>        

      </div>
    </div>
  </section>
</template>

<script>
//import Modal from './../utils/Modal.vue'

export default {
  name: 'TextClassificationHero',
  data () {
    return {
        query_options: [
          "Minhas aulas da faculdade",
          "Meu filho deu entrada no hospital",
          "Gostaria de negociar minha dívida"
      ]
    }
  },
  components: {
    //Modal,
  },
}
</script>
