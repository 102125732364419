<template>
    <section>
      <div class="max-w-6xl mx-auto px-4 sm:px-6">
        <div class="py-12 md:py-20">
  
          <!-- Section header -->
          <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 class="h2 mb-4">
                Why choose Metatext?
            </h1>
            <p class="text-xl text-gray-600">
                Our platform are made to help you build and manage your own custom NLP models. It means no more black-boxes, no more vendor lock-in, and no more data privacy issues.
            </p>
          </div>
  
          <!-- World illustration -->
          

<div class="rounded-xl px-2 max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-2 items-start md:max-w-2xl lg:max-w-none">
    
    <div
        class="h-auto max-w-xl w-full border border-blue-200 hover:border-blue-600 transition ease-in-out cursor-pointer px-4 py-6 rounded-xl">
        <b class="text-blue-600">Customer story</b>
        <h4 class="font-thin text-dark break-words">
            Enhanced Customer Support Automation
        </h4>
        <p class="font-thin text-dark whitespace-pre-line break-words">
            Carl just had a brief idea to automate the customer support operations with a custom AI. He created his first NLP intent detection model in 20 minutes. It has significantly reduced SLA, improved customer satisfaction, and allowed the support agents to focus on more complex issues.
        </p>
        <div class="flex w-full items-center justify-between mt-6">
            <p class="font-magnat text-lg flex items-center">
                <!-- <img class="w-8 h-8 rounded-full object-cover mr-3"
                :src="require(`@/images/news-author-02.jpg`)"> -->
                Carl Silveira, Software Engineer
            </p>
            <a href="#" target="_blank">
                <svg style="margin-right: 0.5em;" xmlns="http://www.w3.org/2000/svg" 
                    class="iconx text-blue-600 icon-tabler icon-tabler-cube-unfolded" 
                    width="42" height="42" 
                    viewBox="0 0 24 24" 
                    stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M2 15h10v5h5v-5h5v-5h-10v-5h-5v5h-5z"></path>
   <path d="M7 15v-5h5v5h5v-5"></path>
</svg>
            </a>
        </div>
        
    </div>
<!-- 2nd-->
    <div
        class="h-auto max-w-xl w-full border border-blue-200 hover:border-blue-600 transition ease-in-out cursor-pointer px-4 py-6 rounded-xl">
        <b class="text-blue-600">Customer story</b>
        <h4 class="font-thin text-dark break-words">
            A Game-Changer in Review Analysis
        </h4>
        <p class="font-thin text-dark whitespace-pre-line break-words">
            Emily faced a challenge to classify +3000 of customer reviews per month. She quickly built a powerful custom NLP review analysis model in just 60 minutes. It transformed her review analysis process by connecting to Google Sheets, providing actionable insights for product strategy.
            
        </p>
        <div class="flex w-full items-center justify-between mt-6">
            <p class="font-magnat text-lg flex items-center">
                <!-- <img class="w-8 h-8 rounded-full object-cover mr-3"
                :src="require(`@/images/news-author-05.jpg`)"> -->
                Emily, Product Manager
            </p>
            <a href="#" target="_blank">
                
                <svg style="margin-right: 0.5em;" xmlns="http://www.w3.org/2000/svg" 
                    class="iconx text-blue-600 icon-tabler icon-tabler-cube-unfolded" 
                    width="42" height="42" 
                    viewBox="0 0 24 24" 
                    stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M2 15h10v5h5v-5h5v-5h-10v-5h-5v5h-5z"></path>
   <path d="M7 15v-5h5v5h5v-5"></path>
</svg>
            </a>
        </div>
    </div>
    <!-- 3rd-->
    <div
        class="h-auto max-w-xl w-full border border-blue-200 hover:border-blue-600 transition ease-in-out cursor-pointer px-4 py-6 rounded-xl">
        <b class="text-blue-600">Customer story</b>
        <h4 class="font-thin text-dark break-words">
            Simplifying Document Analysis
        </h4>
        <p class="font-thin text-dark whitespace-pre-line break-words">
            
            Sarah created her fist NLP document categorization model in just a few clicks. She transformed her chaotic documents into well-organized and easily accessible resources. Her search and analysis became as straightforward as flipping through a well-indexed book.
        </p>
        <div class="flex w-full items-center justify-between mt-6">
            <p class="font-magnat text-lg flex items-center">
                <!-- <img class="w-8 h-8 rounded-full object-cover mr-3"
                :src="require(`@/images/news-author-02.jpg`)"> -->
                Sarah, Founder
            </p>
            <a href="#" target="_blank">
                <svg style="margin-right: 0.5em;" xmlns="http://www.w3.org/2000/svg" 
                    class="iconx text-blue-600 icon-tabler icon-tabler-cube-unfolded" 
                    width="42" height="42" 
                    viewBox="0 0 24 24" 
                    stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M2 15h10v5h5v-5h5v-5h-10v-5h-5v5h-5z"></path>
   <path d="M7 15v-5h5v5h5v-5"></path>
</svg>
            </a>
        </div>
        
    </div>
    <!-- 4th-->
    <div
        class="h-auto max-w-xl w-full border border-blue-200 hover:border-blue-600 transition ease-in-out cursor-pointer px-4 py-6 rounded-xl">
        <b class="text-blue-600">Customer story</b>
        <h4 class="font-thin text-dark break-words">
            Best for Mission-Critical Workflows
        </h4>
        <p class="font-thin text-dark whitespace-pre-line break-words">
            
            Julio was searching for a solution that excelled in mission-critical tasks. Metatext provided the precision and speed he needed to build and manage a robust NLP model, transforming his daily workflow for mission-critical data-driven decisions that continually needs to be monitored.
        </p>
        <div class="flex w-full items-center justify-between mt-6">
            <p class="font-magnat text-lg flex items-center">
                <!-- <img class="w-8 h-8 rounded-full object-cover mr-3"
                :src="require(`@/images/news-author-02.jpg`)"> -->
                Julio, Investment Analyst
            </p>
            <a href="#" target="_blank">
                <svg style="margin-right: 0.5em;" xmlns="http://www.w3.org/2000/svg" 
                    class="iconx text-blue-600 icon-tabler icon-tabler-cube-unfolded" 
                    width="42" height="42" 
                    viewBox="0 0 24 24" 
                    stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M2 15h10v5h5v-5h5v-5h-10v-5h-5v5h-5z"></path>
   <path d="M7 15v-5h5v5h5v-5"></path>
</svg>
            </a>
        </div>
        
    </div>
</div>

  
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'FeaturesWorld',
  }
  </script>