<template>
  <section class="relative">


    <div class="max-w-6xl mx-auto px-4 sm:px-6">

      <!-- Hero content -->
      <div class="pt-32 pb-2 md:pt-40 md:pb-2 md:flex md:justify-between" 
      container>

        <!-- Section header -->
        <div class="text-left my-6 md:my-0 md:flex-shrink-1">
            <div>
                <h1 class="h1 text-5xl md:text-5xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">
                    
                    <span class="bg-clip-text text-blue-500">
                    {{ content.title }}
                    </span>
                </h1>
                
                <div class="max-w-0xl mx-auto">
                    <p class="text-xl text-gray-600 mb-8">
                        Builded using Inference API on-demand.
                    </p>

                    <p class="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">
                    {{ content.description }}
                    </p>
                </div>
            </div>
        </div>

      </div>

    </div>
    <div class="max-w-6xl mx-auto px-4 sm:px-6 pb-4">
      <div class="py-12 md:py-20">

        <div class="max-w-lg mx-auto pb-24">
              <form v-on:submit.prevent="execute">
                <div class="flex flex-wrap -mx-3 mb-4">
                  <div class="w-full px-3">
                      <label class="block text-gray-800 text-sm font-medium mb-1" for="name">
                        <b>INPUT TEXT</b>: Please select a query example
                      </label>
                    <select v-model="query_selected" id="name" row="10" class="form-input w-full text-gray-800" placeholder="Enter your name" required >
                        <option selected disabled hidden value="">
                        Choose a query example
                        </option>
                        <option 
                        v-for="option in query_options" 
                        :key="option.id" 
                        :value="option.query"
                        >
                            {{ option.name }}
                        </option>
                    </select>
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-4">
                  <div class="w-full px-3">
                    <textarea disabled id="name" row="10" class="form-input w-full text-gray-800" 
                    placeholder="Query text" :value="query_selected" required />
                  </div>
                  <div class="w-full px-3">
                    <button :disabled="loading" type="submit" class="btn text-white bg-blue-600 hover:bg-blue-700 w-full">
                        {{ button }}
                    </button>
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-4">
                  
                  <div class="w-full px-3">
                      <label class="block text-gray-800 text-sm font-medium mb-1" for="name">
                    <b>RESULT</b>: Inference output<br>
                      </label>
                    {{ results }}
                    <div v-if="loading">Loading cached results</div>
                  </div>
                </div>
              </form>
        </div>        

      
      

          <!-- Related content -->
          <div class="border-t border-gray-200"></div>
    <div class="mt-8">
      <h2 class="h2 mb-8">
          Inference APIs
      </h2>
      <span v-for="content in apis" :key="content.id">
      <a class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" 
      :href="content.url">
        <div>
          <div class="text-normal font-medium mb-1">{{ content.title }}</div>
          <div class="text-sm text-gray-600">{{ content.body }}</div>
        </div>
        <svg class="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </a>
      </span>
    </div>
</div>
    </div>
  </section>
</template>

<script>
//import Modal from './../utils/Modal.vue'

export default {
  name: 'Products',
  data () {
    return {
        query: null,
        path: 'test',
        query_selected: "",
        results: "Input text above or try analyzer with our example",
        content: {
            title: "Ticket Classification API", 
            description: "Classify support tickets to specific categories such as shipping status, service type, product quality and etc. Metatext allows anyone to create custom text classification models or use built-in models, and integrate on your workflow."
        },
        apis: [
            {"title": "ticket classification", "body": "asd"},
            {"title": "sentiment analysis", "body": "asd"}
        ],
        loading: false,
        button: "Compute"
    }
  },
  components: {
    //Modal,
  },
  created(){
      // this.execute(this.$route.path)
  },
  methods: {
      execute() {
          this.loading = true;
          this.button = "Computing..."
          var test = this.query_options.find(c => c.query === this.query_selected)
          // console.log(test.id, test.name)
          this.results = "..."

          setTimeout(() => {
           this.loading = false;
           this.results = `Prediction: ${test.response}`
           this.button = "Compute"
          }, 2000)
          
      },
  }
}
</script>
