var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col min-h-screen overflow-hidden"},[_c('Header'),_c('main',{staticClass:"flex-grow"},[_c('section',[_c('div',{staticClass:"max-w-6xl mx-auto px-4 sm:px-6"},[_c('div',{staticClass:"pt-32 pb-12 md:pt-40 md:pb-20"},[_c('CtaHub'),_vm._m(0),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table ","search-options":{
                      enabled: true,
                      trigger: 'enter',
                      skipDiacritics: true,
                      placeholder: 'What are you looking for? i.e., type a model <Enter>',
                      
                  },"pagination-options":{
                      enabled: true,
                      mode: 'records',
                      perPage: 10,
                      position: 'top',
                      perPageDropdown: [10, 25, 50, 100],
                      dropdownAllowAll: true,
                      setCurrentPage: 1,
                      nextLabel: 'next',
                      prevLabel: 'prev',
                      rowsPerPageLabel: 'Rows per page',
                      ofLabel: 'of',
                      pageLabel: 'page', // for 'pages' mode
                      allLabel: 'All',
                  }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'title')?_c('span',[_c('a',{staticClass:"hover:underline",attrs:{"href":'/models/' + props.row.title.replace('/', '-').toLowerCase()}},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]):_c('span',[_c('a',{staticClass:"hover:underline",attrs:{"href":'/models/' + props.row.title.replace('/', '-').toLowerCase()}},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])])]}}])}),_c('br'),_c('br'),_c('div',{staticClass:"pt-6"},[_c('div',{staticClass:"flex flex-col text-center sm:text-left sm:flex-row sm:justify-between sm:items-center"},[_c('div',{staticClass:"font-medium mb-4 md:mb-0"},[_vm._v("Was this page helpful? Share to help more people.")]),_c('twitter-button',{staticClass:"share-button--outline"}),_c('facebook-button',{staticClass:"share-button--outline"}),_c('whatsApp-button',{staticClass:"share-button--outline"}),_c('telegram-button',{staticClass:"share-button--outline"}),_c('linkedIn-button',{staticClass:"share-button--outline"})],1),_c('hr',{staticClass:"w-full h-px pt-px bg-gray-200 border-0 mt-6"})])],1)])]),_c('CtaAlternative')],1),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"max-w-3xl mx-auto text-center pb-12 md:pb-16"},[_c('h1',{staticClass:"h1 mb-4"},[_vm._v("NLP Models Repository")]),_c('h2',{staticClass:"h4 mb-4"},[_vm._v(" List of Natural Language Processing Models to use in your AI projects ")]),_c('p',{staticClass:"text-xl text-gray-600"},[_vm._v(" Transformers models covering tasks from Summarization, Text Classification, to Text Generation. We hope you find this library useful in your development endeavors ")])])
}]

export { render, staticRenderFns }