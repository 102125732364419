<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <section>
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <div class="pt-32 pb-12 md:pt-40 md:pb-20">

            <!-- Main content -->
            <div class="md:flex md:justify-between" data-sticky-container>

              <!-- Sidebar -->
              <aside class="relative my-12 md:my-0 md:w-64 md:mr-12 lg:mr-20 md:flex-shrink-0">
                <div data-sticky data-margin-top="100" data-sticky-for="768" data-sticky-wrap>

                  <!-- Search form -->
                  <div class="mb-4 pb-4 border-b border-gray-200">
                    <div class="flex flex-wrap">
                      <div class="w-full">
                        <a class="flex items-center hover:underline" href="/datasets">
                          <div class="flex items-center flex-grow">
                            
                            <span>Go back to search apis</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <button class="flex items-center justify-between text-lg font-medium text-gray-900 w-full my-4 md:hidden">
                    <span>APIs navigation</span>
                    <svg class="w-3 h-3 fill-current text-blue-600 flex-shrink-0 ml-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <rect y="7" width="16" height="2" rx="1" class="transform origin-center transition duration-200 ease-out"/>
                      <rect y="7" width="16" height="2" rx="1" class="transform origin-center rotate-90 transition duration-200 ease-out" />
                    </svg>
                  </button>

                  <NavAPIs :col="v"/>

                </div>
              </aside>

              <!-- Page container -->
              
          <div class="md:flex-grow">
                <CtaHub :task="filter"/>
                <div class="text-lg text-gray-600">
                  <h1 class="h1 text-gray-900 mb-4">
                    List of {{ filter }} APIs for Machine Learning Projects
                  </h1>

                  <p>

                    <span>
                      Free and proprietary machine learning APIs are a good way to get started AI projects without much efforts.
                      Although there are hard to find high quality and performance APIs, like {{ filter }} APIs.
                      We compiled a list of APIs to you start your machine learning (ML) project right now.
                    We collected a list of public, free and proprietary {{ filter }} NLP APIs, 
                    <router-link to="/apis" class="hover:underline;">a large curated repository</router-link> from sentiment analysis, summarization, to law contract analysis APIs.
                    
                    Covering a wide gamma of NLP use cases, from author profiling, classification, to questions and answers. 
                    </span>
                  </p>
                  <br>
                  <p>
                    <b>Let’s get started with APIs!</b>
                  </p>

    <!-- Related content -->
    <div class="mt-8">
      <!--h3 class="h3 mb-8">Related</h3-->
      <span v-for="content in contents" :key="content.id">
      <a class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" 
      :href="content.url">
        <div>
          <div class="text-normal font-medium mb-1">{{ content.title }}</div>
          <div class="text-sm text-gray-600">{{ content.body }}</div>
        </div>
        <svg class="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </a>
      </span>
    </div>
                  
                </div>

      <hr class="w-full h-px pt-px bg-gray-200 border-0 mt-8" />


              </div>

            </div>

          </div>
        </div>
      </section>

    
      
      <CtaAlternative />      

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import CtaAlternative from './../partials/CtaAlternative.vue'
import Footer from './../partials/Footer.vue'
import NavAPIs from './../partials/NavAPIs.vue'
import json_datasets from './../assets/json/apis_5.json'
import CtaHub from './../partials/CtaHub.vue'

export default {
  name: 'Datasets',
		data () {
			return {
            ready: false,
            contents: [],
            filter: '',
            item: '',
            v: '',
            sidebarNavOpen: false,
            sidebarLinkOpen: true,
            apis: ['News Services', 'Machine Learning', 'Sentiment', 'Words',
       'Nutrition', 'Artificial Intelligence',
       'Natural Language Processing', 'Framework', 'Q&A', 'Text',
       'Translation', 'Business', 'Healthcare', 'Cryptocurrency',
       'Dictionary', 'Analytics', 'Semantics', 'Data', 'Documents',
       'Bots', 'Language', 'Extraction', 'Education', 'Visualizations',
       'Summary', 'Travel', 'eCommerce', 'Stocks',
       'Software-as-a-Service', 'Food', 'Text-to-Speech', 'Accounting',
       'Feeds', 'Tools', 'Voice', 'Augmented Reality', 'Audio', 'OCR',
       'Names', 'Search', 'Lists', 'Languages', 'Content', 'Cloud',
       'Customer Service', 'Community', 'Chat', 'Location',
       'Intelligence', 'Writing', 'Calendars', 'Images', 'Customization',
       'Data Mining', 'Tagging', 'Social', 'Recognition', 'Open Data',
       'Keywords', 'Backend', 'Transcription', 'Recommendations']
            }
        },
        components: {
            Header,
            CtaAlternative,
            CtaHub,
            Footer,
            NavAPIs
        },
        beforeRouteLeave (to, from, next) {
            if (to.meta.componentName === 'Page') {
            this.loadContents(to.path, next)
            } else {
            next()
            }
        },
        beforeRouteUpdate (to, from, next) {
            this.loadContents(to.path, next)
        },
        created(){
            this.loadContents(this.$route.path)
        },
        mounted(){
            this.loadContents(this.$route.path)
        },
        methods: {
            loadContents (path, callback) {
                
                
                
                var p_str = path.split('/')[2]//.split("-").join(" ")
                
                // console.log(path.split('/')[-1])
                // console.log(p, p_str)

                var data = json_datasets

                var value = p_str.split('-')
                value.pop()
                var col = "Category"
                
                // this.content = response.data
                this.filterBy(data, col, value.join(' ').replace('(','').replace(')','').replace(':',''))

                this.v = col
                // console.log(data[0])
                // console.log(data[data.length-1])


                //if (Array.isArray(data.body)) {
                //  this.content.body = data.body.join(" ")
                //}

                // var total = data.length
                

                String.prototype.capitalize = function() {
                    return this.charAt(0).toUpperCase() + this.slice(1);
                }

                this.filter = String(value.join(' ').replace('(','').replace(')','').replace(':','')).capitalize()

                document.title = 'APIs for '+ this.filter +' - NLP Resource'


                this.ready = true

                if (typeof callback === 'function') {
                    callback()
                }

                
            },
            filterBy(data, col, value){

                // iterate over each element in the array
                for (var i = 0; i < (data.length-1); i++){
                    // look for the entry with a matching `code` value
                    if ( value !== "all-Category" && (String(data[i][col].replace(/\s+/g, '-').toLowerCase()).includes(String(value.toLowerCase()))) ){
                    // we found it
                    // obj[i].name is the matched result
                    // console.log(data[i])
                    
                        this.contents.push({
                            id: Math.floor(Math.random() * 111111111111111),
                            title: data[i].apiname,
                            tag: data[i],
                            body: data[i].intro,
                            url: "/apis/"+data[i].apiname.replace(/\s+/g, '-').toLowerCase()
                        })

                    // this.content = data[i]
                    } else if ( value.toLowerCase() === "all-category") {

                        this.contents.push({
                            id: Math.floor(Math.random() * 111111111111111),
                            title: data[i].apiname,
                            tag: data[i],
                            body: data[i].intro,
                            url: "/apis/"+data[i].apiname.replace(/\s+/g, '-').toLowerCase()
                        })
                        
                    }
                }
                
                
            }
        }
	}
</script>
