<template>
<section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
        <div class="py-12 md:py-20 border-t border-gray-200">

            <!-- Section header -->
            <div class="max-w-3xl mx-auto text-center pb-20">
                <h2 class="h2">Frequently Asked Questions</h2>
            </div>

            <!-- Faqs -->
            <ul class="max-w-3xl mx-auto pl-12">
              <Accordion title="What is Metatext?" active>
                    Metatext is an interactive NLP platform that empowers you to effortlessly train and deploy NLP models without code. For both developers and non developers on mind, you can create state-of-the-art models in minutes and integrate them into your applications via APIs.
                    </Accordion>
                    
                    <Accordion title="Do I need to have expertise in Machine Learning (ML) and NLP to use Metatext?" active>
                        No, Metatext is designed with user-friendliness in mind. You can harness its capabilities without needing to be well-versed in ML or NLP algorithms. It offers an intuitive interface for easy use.
                    </Accordion>

                    <Accordion title="Who is Metatext intended for?" active>
                        Metatext caters to a diverse audience, including developers, business analysts, data scientists, and anyone seeking to rapidly and efficiently train and deploy NLP models to address various text classification needs.
                    </Accordion>

                    <Accordion title="What NLP tasks can I perform with Metatext?">
                        Metatext is versatile and supports various NLP tasks such as text classification, intent detection, sentiment analysis, topic categorization, spam detection, and more. It is also evolving to introduce custom text extraction (NER) and generation (text2text) NLP models in the near future.
                    </Accordion>
                    <Accordion title="In what languages is Metatext available?">
                        Metatext is designed to work with multiple languages, offering multilingual support to accommodate a global user base.
                    </Accordion>
                    <Accordion title="What is the pricing structure for Metatext?">
                        You can find detailed pricing information on our website. Metatext offers a range of pricing plans, including a free starter plan, pro plan, and enterprise plan. Each plan provides different levels of credits and features to meet your specific needs.
                    </Accordion>
                    <Accordion title="Is the deployment of models scalable with Metatext?">
                        Yes, Metatext offers scalability in model deployment. You can effortlessly deploy your NLP models to the cloud, ensuring that they can handle a growing volume of data and requests as your business or project scales.
                    </Accordion>
                    <Accordion title="Can I upload my own datasets, and what file formats are supported?">
                        Yes, you can upload your own datasets to Metatext. It supports a wide range of file formats, including popular options such as CSV, TXT, PDF, DOCX, and more. Additionally, we provide support for importing data via API,
                    </Accordion>
                    <Accordion title="How many labels can I use in Metatext for text classification?">
                        Metatext offers the flexibility to use an unlimited number of labels for text classification, allowing you to categorize your data effectively and precisely.
                    </Accordion>
                    <Accordion title="Can I download the trained models I create with Metatext?">
                        Metatext primarily focuses on the deployment of models for real-world use cases. While you may not be able to download the trained models directly, you can seamlessly integrate them into your applications through APIs for practical applications.
                    </Accordion>
                    <Accordion title="What is the primary purpose of Metatext, and how does it differ from ChatGPT and GPT?">
                        Metatext is designed for creating and deploying custom NLP models for various text classification tasks. ChatGPT and GPT, on the other hand, are general-purpose language models that focus on generating human-like text and conversational AI applications.
                    </Accordion>
                    
              <!--Accordion title="What is the difference between the Free and Paid versions?" active>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </Accordion>
              <Accordion title="How can I change the owner of a workspace?">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </Accordion-->
              <span class="block border-t border-gray-200" aria-hidden="true"></span>
            </ul>
            
        </div>
    </div>
</section>
</template>

<script>
import Accordion from './../utils/Accordion.vue'

export default {
  name: 'Faqs',
  components: {
    Accordion
  },  
}
</script>