<template>
    <section class="relative">
  
      <!-- Illustration behind hero content -->
      <div class="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stop-color="#FFF" offset="0%" />
              <stop stop-color="#EAEAEA" offset="77.402%" />
              <stop stop-color="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fill-rule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>
  
      <div class="max-w-6xl mx-auto px-4 sm:px-6">
  
        <!-- Hero content -->
        <div class="pt-32 pb-12 md:pt-40 md:pb-20">
  
          <!-- Section header -->
          <div class="text-center pb-12 md:pb-16">
            <h1 class="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">
                <span class="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-blue-400">
                    {{ $t('hero.headline1') }}
                </span>
                <br>
                <span class="">
                    {{ $t('hero.headline2') }}
                </span>
            </h1>
            <!-- <h1 class="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">
                {{ $t('hero.headline1') }}<br> 
                <span class="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-blue-400">
                    {{ $t('hero.headline2') }}
                </span>
            </h1> -->
            <div class="max-w-3xl mx-auto">
              <p class="text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">
                {{ $t('hero.description') }}
             </p>
              <div class="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center" data-aos="zoom-y-out" data-aos-delay="300">
                <!-- <div>
                    <a class="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0" href="/signup">
                        🔥 {{ $t('hero.signup') }}
                        <svg class="w-3 h-3 fill-current text-blue-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill-rule="nonzero" />
                    </svg>
                    </a>
                </div> -->
                <div>
                    <a class="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0" href="/book-intro-call">
                        📅&nbsp;&nbsp;{{ $t('hero.book') }}
                        <svg class="w-3 h-3 fill-current text-blue-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill-rule="nonzero" />
                    </svg>
                    </a>
                </div>
                <div>
                    
                    <!-- <a href="https://cal.com/rafaelsandroni/metatext-demo" target="_blank" class="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4">
                        📅 {{ $t('hero.book') }}
                    </a> -->
                </div>
              </div>
            </div>
          </div>
  
          <!-- Hero image -->
          <div>
            <div class="relative flex justify-center mb-8" data-aos="zoom-y-out" data-aos-delay="450">
              <div class="flex flex-col justify-center">
                <img class="mx-auto" :src="require(`@/images/hero.png`)" width="968" height="432" alt="Metatext - classify and extract text" />
                
              </div>
              
              <!-- <button class="absolute top-full flex items-center transform -translate-y-1/2 bg-white rounded-full font-medium group p-4 shadow-lg" @click.prevent.stop="videoModalOpen = true" aria-controls="modal">
                <svg class="w-6 h-6 fill-current text-gray-400 group-hover:text-blue-600 shrink-0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
                  <path d="M10 17l6-5-6-5z" />
                </svg>
                <span class="ml-3">
                    <img class="w-9 h-9 rounded-full" :src="require(`@/images/news-author-01.jpg`)" width="36" height="36" alt="User 01" />
                </span>
                <span class="ml-3">
                    Watch Clara build a chatbot intent model (2 min)</span>
              </button> -->
            </div>
  
            <!-- Modal -->
            <Modal id="modal" ariaLabel="modal-headline" :show="videoModalOpen" @handleClose="videoModalOpen = false">
              <div class="relative pb-9/16">
                <video ref="video" className="absolute w-full h-full" width="1920" height="1080" loop controls>
                  <source src="" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Modal>

            
  
          </div>
  
        </div>
  
      </div>
    </section>
  </template>
  
  <script>
  import { ref, watch } from 'vue'
  import Modal from './../utils/Modal.vue'
  
  export default {
    name: 'HeroHome',
    components: {
      Modal,
    },
    setup() {
      const videoModalOpen = ref(false)
      const video = ref(null)
  
      watch(videoModalOpen, () => {
        videoModalOpen.value ? video.value.play() : video.value.pause()
      })    
  
      return {
        videoModalOpen,
        video,
      }    
    }
  }
  </script>