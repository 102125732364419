<template>
    <section class="relative">
  
      <!-- Section background (needs .relative class on parent and next sibling elements) -->
      <div class="absolute inset-0 bg-gray-100 pointer-events-none mb-16" aria-hidden="true"></div>
      <div class="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>
  
      <div class="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div class="pt-12 md:pt-20 pb-20">
  
          <!-- Section header -->
          <div class="max-w-5xl mx-auto text-center pb-1 md:pb-1">
            <h1 class="h2 mb-4">
                <!-- Empower teams to annotate, fine-tune and monitor custom NLP models -->
                <!-- Turn your data and expertise into <span data-entity="metal">gold</span> -->
                {{  $t('features.headline1') }}
                
            </h1>
          </div>
          <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <p class="text-xl text-gray-600">
                {{ $t('features.description1') }}
            </p>
            
          </div>
  
          <!-- Top image -->
          <!-- <div class="pb-12 md:pb-16 relative inline-flex flex text-center items-center">
            <img :src="require(`@/images/hero.svg`)" width="704" alt="Features top" />
          </div> -->

          <!-- Items -->
          <div class="max-w-sm mx-auto grid gap-6 md:grid-cols-3 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">
  
  <!-- 1st item -->
  <!-- <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
    <svg class="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <rect class="fill-current text-blue-600" width="64" height="64" rx="32" />
        <g stroke-width="2">
          <path class="stroke-current text-blue-300" d="M34.514 35.429l2.057 2.285h8M20.571 26.286h5.715l2.057 2.285" />
          <path class="stroke-current text-white" d="M20.571 37.714h5.715L36.57 26.286h8" />
          <path class="stroke-current text-blue-300" stroke-linecap="square" d="M41.143 34.286l3.428 3.428-3.428 3.429" />
          <path class="stroke-current text-white" stroke-linecap="square" d="M41.143 29.714l3.428-3.428-3.428-3.429" />
        </g>
      </g>
    </svg>
    <h4 class="text-xl font-bold text-blue-600 leading-snug tracking-tight mb-1">
        {{ $t('features.feature1') }}
    </h4>
    <hr>
    <h4 class="text-lg text-center font-bold leading-snug tracking-tight mb-1">
        Iterative process to build better NLP models in few minutes
    </h4>
    <p class="text-gray-600 text-center">
        Intuitive tools to train, tune and use NLP models like an expert
      </p>
  </div> -->

  <!-- 2nd item -->
  <!-- <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
    <svg class="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                  <rect class="fill-current text-blue-600" width="64" height="64" rx="32" />
                  <g stroke-width="2" stroke-linecap="square">
                    <path class="stroke-current text-white" d="M29.714 40.358l-4.777 2.51 1.349-7.865-5.715-5.57 7.898-1.147L32 21.13l3.531 7.155 7.898 1.147L40 32.775" />
                    <path class="stroke-current text-blue-300" d="M44.571 43.429H34.286M44.571 37.714H34.286" />
                  </g>
                </g>
              </svg>
              
    <h4 class="text-xl font-bold text-blue-600 leading-snug tracking-tight mb-1">
        {{ $t('features.feature2') }}
    </h4>
    <h4 class="text-lg text-center font-bold leading-snug tracking-tight mb-1">
        From training strategy to algorithm that best fit with your data
    </h4>
    <p class="text-gray-600 text-center">
        Add text data, annotate and let see the magic. Multilingual support
    </p>
  </div> -->

  <!-- 3nd item -->
  <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
    


    <div class="max-w-sm bg-white ">
        <a href="#">
            <img class="rounded-t-lg" width="100%" :src="require(`@/images/ai-task-classification.svg`)" alt="Text Classification NLP LLM fine-tuned" />
        </a>
        <div class="p-5">
            <a href="#">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                     {{ $t('features.feature1') }}
                </h5>
            </a>
            
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                Classify any text or document into categories.
            </p>
            <p class="mb-0 font-normal text-gray-500 dark:text-gray-300">
            Possible use cases:
            </p>
            <span class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500 mt-4 mr-1">
            Binary
            </span>
            <span class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500 mt-4 mr-1">
            Multi-class
            </span><br>
            <span class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500 mt-2 mr-0">
            Multi-label
            </span>
            <span class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500 mt-2 mr-0">
            Sentiment
            </span>
            <span class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500 mt-2 mr-0">
            Topic
            </span>
            <span class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500 mt-2 mr-0">
            Intents and more
            </span><br>
            
        </div>
    </div>
    
    </div>
    <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
    


    <div class="max-w-sm bg-white ">
        <a href="#">
            <img class="rounded-t-lg" width="100%" :src="require(`@/images/ai-task-extraction.svg`)" alt="Text Tagging NLP LLM fine-tuned" />
        </a>
        <div class="p-5">
            <a href="#">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {{ $t('features.feature2') }}
                </h5>
            </a>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                Extract, normalize and structure key information from any text or document.
            </p>
            <p class="mb-0 font-normal text-gray-500 dark:text-gray-300">
            Possible use cases:
            </p>
            <span class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500 mt-4 mb-4">
            Extract key information
            </span><br>
            <span class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500 mt-4 mb-4">
            Entity recognition
            </span>
            <span class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500 mt-4 mb-4">
            Keywords
            </span>
            
        </div>
    </div>
    
    </div>
  <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
    


<div class="max-w-sm bg-white ">
    
    <a href="#">
        <img class="rounded-t-lg" width="100%" :src="require(`@/images/ai-task-generation.svg`)" alt="Text Generation NLP LLM fine-tuned" />
    </a>
    <div class="p-5">
        <a href="#">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white ">
                {{ $t('features.feature3') }}
            </h5>
        </a>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
            Generate text from scratch or fine-tune LLMs to your domain.
        </p>
        <p class="mb-0 font-normal text-gray-500 dark:text-gray-300">
            Possible use cases:
        </p>
        <span class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500 mt-4 mb-4">
            Generative AI applications
        </span>
        <span class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500 mt-4 mb-4">
            RAG
        </span>
        <span class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500 mt-4 mb-4">
            Summarization
        </span>
        <span class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500 mt-4 mb-4">
            LLM fine-tuning
        </span><br>
        <span class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
            Question & Answering
        </span>

        <span class="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
            Chatbot
        </span>

        
      
    </div>
</div>

</div>




  
  
  </div>
  <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16 pt-12 md:pt-16">
    <h4>
        
    </h4>
  <p class="text-xl text-gray-600">
            Would you like to see other tasks?
            <br><br>

            <a class="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4" href="https://tally.so/r/3EdBKl" target="_blank">Let us know</a>
    </p>
  </div>


<div class="relative mx-auto bg-gray-100 rounded-t-xl h-[172px] max-w-[301px] md:h-[294px] md:max-w-[512px]">
    <div class="max-w-5xl mx-auto text-center pb-1 md:pb-1">
            <h1 class="h2 mb-4">
                <!-- Empower teams to annotate, fine-tune and monitor custom NLP models -->
                <!-- Turn your data and expertise into <span data-entity="metal">gold</span> -->
                AI platform to distill the best of LLMs
                
            </h1>
          </div>
          
        <div class="max-w-3xl mx-auto text-center pb-1 md:pb-1">
                <p class="text-xl text-gray-600">
                    Your data refined into private and scalable custom models in a matter of minutes by our distillation methodology that combine the power of LLMs into an iterative process to efficiently train smaller models x10 better with less data and time.
                </p>
                
                
              </div>
    <div class="rounded-xl overflow-hidden h-[140px] md:h-[262px]">
        <img class="md:max-w-none absolute w-100 right-0 bottom-0 transform animate-float animation-delay-500" :src="require(`@/images/model-icon.png`)" width="180" height="18" alt="Fine-tune LLM models" style="top:25%" />
        <img class="md:max-w-none absolute w-100 right-0 bottom-0 transform animate-float animation-delay-500" :src="require(`@/images/rag-icon.png`)" width="180" height="18" alt="RAG Chatbots" style="top:45%" />
        <img class="md:max-w-none absolute w-100 right-0 bottom-0 transform animate-float animation-delay-500" :src="require(`@/images/custom-icon.png`)" width="180" height="18" alt="Private and custom LLM models" style="top:65%" />

        <img class="md:max-w-none absolute w-100 left-0 transform animate-float" :src="require(`@/images/raw-data-icon.png`)" width="180" height="18" alt="Annotate raw unstructured data" style="top:32%" />

        <img class="md:max-w-none absolute w-100 left-0 transform animate-float" :src="require(`@/images/expert-icon.png`)" width="180" height="18" alt="Domain-specific AI models" style="top:52%" />
<center>
        <img class="text-center center image-center dark:hidden h-[540px] md:h-[262px] w-full rounded-xl" alt="Metatext is a product to help in distillation LLMs to smaller models" :src="require(`@/images/screen.png`)" style="max-width: 52rem;"/>
    </center>
        
    </div>
</div>
<div class="relative mx-auto bg-white dark:bg-gray-700 rounded-b-xl h-[24px] max-w-[301px] md:h-[42px] md:max-w-[512px]"></div>
<div class="relative mx-auto bg-white rounded-b-xl h-[55px] max-w-[83px] md:h-[95px] md:max-w-[142px]"></div>


          <!-- Section content -->
          <div class="md:grid md:grid-cols-12 md:gap-6 pt-24">
  
            <!-- Content -->
            <div class="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6">
              <div class="md:pr-4 lg:pr-12 xl:pr-16 mb-8">
                <h3 class="h3 mb-3">
                    Just explain what you need
                </h3>
                <p class="text-xl text-gray-600">
                    You don't need to code anymore. Just explain what you need to the model and let it learn from your data and valuable expertise.
                </p>
              </div>
              <!-- Tabs buttons -->
              <div class="mb-8 md:mb-0">
                <button :class="tab !== '1' ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'" class="text-left flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3" @click.prevent="tab = '1'">
                  <div>
                    <div class="font-bold leading-snug tracking-tight mb-1">
                        1. Train with less data and annotation time
                    </div>
                    <div class="text-gray-600">
                        Put your data and expertise into our platform and let it do the rest. Our AutoNLP engine will train and fine-tune the best model for your use case.
                         <!-- <br>
                         <b>See how we're better than OpenAI GPT</b> -->
                    </div>
                  </div>
                  <!-- <div class="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow shrink-0 ml-3">
                    <svg class="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                    </svg>
                  </div> -->
                </button>
                <button :class="tab !== '2' ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'" class="text-left flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3" @click.prevent="tab = '2'">
                  <div>
                    <div class="font-bold leading-snug tracking-tight mb-1">
                       2. Evaluate to get a trustful your model
                    </div>
                    <div class="text-gray-600">
                        Evaluation matters. Metatext will help you to understand how your model is performing and how to improve it. Trustful models are key to success.
                    </div>
                  </div>
                  <!-- <div class="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow shrink-0 ml-3">
                    <svg class="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.854.146a.5.5 0 00-.525-.116l-11 4a.5.5 0 00-.015.934l4.8 1.921 1.921 4.8A.5.5 0 007.5 12h.008a.5.5 0 00.462-.329l4-11a.5.5 0 00-.116-.525z" fill-rule="nonzero" />
                    </svg>
                  </div> -->
                </button>
                <button :class="tab !== '3' ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'" class="text-left flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out" @click.prevent="tab = '3'">
                  <div>
                    <div class="font-bold leading-snug tracking-tight mb-1">
                        3. Deploy and integrate with your systems
                    </div>
                    <div class="text-gray-600">
                        Deploy your model in a few clicks and integrate with your systems via API, Zapier, Google Sheets, Docker, AWS, Hugging Face, and more.


                    </div>
                  </div>
                  <!-- <div class="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow shrink-0 ml-3">
                    <svg class="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.334 8.06a.5.5 0 00-.421-.237 6.023 6.023 0 01-5.905-6c0-.41.042-.82.125-1.221a.5.5 0 00-.614-.586 6 6 0 106.832 8.529.5.5 0 00-.017-.485z" fill="#191919" fill-rule="nonzero" />
                    </svg>
                  </div> -->
                </button>
              </div>
            </div>
  
            <!-- Tabs items -->
            <div class="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="zoom-y-out">
              <div class="relative flex flex-col text-center lg:text-right">
                <!-- Item 1 -->
                <transition
                  enter-active-class="transition ease-in-out duration-700 transform order-first"
                  enter-from-class="opacity-0 translate-y-16"
                  enter-to-class="opacity-100 translate-y-0"
                  leave-active-class="transition ease-in-out duration-300 transform absolute"
                  leave-from-class="opacity-100 translate-y-0"
                  leave-to-class="opacity-0 -translate-y-16"
                >
                  <div class="w-full" v-show="tab === '1'">
                    <div class="relative inline-flex flex-col">
                        <img class="md:max-w-none mx-auto rounded transform animate-float" :src="require(`@/images/annotation3.svg`)" width="500" height="375" alt="Data labeling for LLMs and NLP" style="top:0%"/>
                        
                      
                      
                      <img class="md:max-w-none absolute w-full left-0 transform animate-float" :src="require(`@/images/annotation4.svg`)" width="500" height="147" alt="Text Classification with AutoLabel" style="top:100%" />
                      <img class="md:max-w-none absolute w-full left-0 bottom-0 transform animate-float animation-delay-1000" :src="require(`@/images/annotation2.svg`)" width="500" height="167" alt="Text Annotation" style="top:200%" />
                      
                      <!-- <img class="md:max-w-none absolute w-full left-0 transform animate-float" :src="require(`@/images/hero.png`)" width="500" height="147" alt="Element 01" style="top:22%" />
                      <img class="md:max-w-none absolute w-full left-0 transform animate-float animation-delay-500" :src="require(`@/images/hero.png`)" width="500" height="158" alt="Element 02" style="top:39%" />
                      <img class="md:max-w-none absolute w-full left-0 bottom-0 transform animate-float animation-delay-1000" :src="require(`@/images/hero.png`)" width="500" height="167" alt="Element 03" style="top:77%" /> -->
                    </div>
                  </div>
                </transition>
                <!-- Item 2 -->
                <transition
                  enter-active-class="transition ease-in-out duration-700 transform order-first"
                  enter-from-class="opacity-0 translate-y-16"
                  enter-to-class="opacity-100 translate-y-0"
                  leave-active-class="transition ease-in-out duration-300 transform absolute"
                  leave-from-class="opacity-100 translate-y-0"
                  leave-to-class="opacity-0 -translate-y-16"
                >
                  <div class="w-full" v-show="tab === '2'">
                    <div class="relative inline-flex flex-col">
                      <img class="md:max-w-none mx-auto rounded right-50 bottom-0 animate-float animation-delay-500" :src="require(`@/images/report-model.png`)" width="300" height="300" alt="LLM model report" />
                      
                      <img class="md:max-w-none absolute w-full right-60 bottom-0 transform animate-float animation-delay-500" :src="require(`@/images/report-results.png`)" width="500" height="158" alt="LLM evaluation" style="top:90%" />
                      <!-- <img class="md:max-w-none absolute w-full left-0 bottom-0 transform animate-float animation-delay-1000" :src="require(`@/images/hero.png`)" width="500" height="167" alt="Element 03" style="top:18%" />
                      <img class="md:max-w-none absolute w-full left-0 transform animate-float animation-delay-500" :src="require(`@/images/hero.png`)" width="500" height="158" alt="Element 02" style="top:40%" />
                      <img class="md:max-w-none absolute w-full left-0 transform animate-float" :src="require(`@/images/hero.png`)" width="500" height="147" alt="Element 01" style="top:79%" /> -->
                    </div>
                  </div>
                </transition>
                <!-- Item 3 -->
                <transition
                  enter-active-class="transition ease-in-out duration-700 transform order-first"
                  enter-from-class="opacity-0 translate-y-16"
                  enter-to-class="opacity-100 translate-y-0"
                  leave-active-class="transition ease-in-out duration-300 transform absolute"
                  leave-from-class="opacity-100 translate-y-0"
                  leave-to-class="opacity-0 -translate-y-16"
                >
                  <div class="w-full" v-show="tab === '3'">
                    <div class="relative inline-flex flex-col">
                        
                      <img class="md:max-w-none mx-auto rounded right-20 transform animate-float" :src="require(`@/images/connect-api2.png`)" width="300" height="375" alt="LLM connect to API" />
                      <img class="md:max-w-none absolute w-full right-60 transform animate-float" :src="require(`@/images/connect-zapier.png`)" width="500" height="147" alt="Metatext and Zapier" style="top:92%" />
                      <img class="md:max-w-none absolute w-full left-0 transform animate-float animation-delay-500" :src="require(`@/images/connect-sheets.png`)" width="500" height="158" alt="Google Sheets and Metatext" style="top:149%" />
                       <img class="md:max-w-none absolute w-full right-60 transform animate-float animation-delay-500" :src="require(`@/images/connect-docker.png`)" width="500" height="158" alt="Metatext Private Docker" style="top:209%" />
                       <img class="md:max-w-none absolute w-full left-0 transform animate-float animation-delay-500" :src="require(`@/images/connect-aws.png`)" width="500" height="158" alt="Metatext AWS Marketplace" style="top:289%" />
                       
                      <!--<img class="md:max-w-none absolute w-full left-0 bottom-0 transform animate-float animation-delay-1000" :src="require(`@/images/hero.png`)" width="500" height="167" alt="Element 03" style="top:77%" /> -->
                    </div>
                  </div>
                </transition>
              </div>
            </div>

  
          </div>
          
          
          <div class="max-w-xs pt-10 pb-10 mx-auto sm:max-w-none sm:flex" data-aos="zoom-y-out" data-aos-delay="300">
                
                <!-- <div>
                  <a class="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4" href="#0">Learn more</a>
                </div> -->
              </div>

        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'FeaturesHome',
    data: function () {
      return {
        tab: '1',
      }
    },
  }
  </script>