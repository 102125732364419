<template>
    <section class="bg-gradient-to-b from-gray-100 to-white border-t border-gray-200">

    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="py-12 md:py-16">

        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center ">
            <!--h4 class="h4">HOW IT WORKS</h4-->
          <h2 class="h2">
            Step by step
          </h2>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Process',
}
</script>