<template>
  <footer>
    <div class="bg-black text-gray-300 max-w-6xl mx-auto px-4 sm:px-6">

      <!-- Top area: Blocks -->
      <div class="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-black">

        <!-- 1st block -->
        <div class="sm:col-span-12 lg:col-span-3">
          <div class="mb-2">
            <!-- Logo -->
            <router-link to="/" class="inline-block" aria-label="Cruip">
                <div class="max-w-screen-xl flex flex-wrap items-center text-gray-300 justify-between mx-auto p-1 dark:text-white">
    <a href="https://flowbite.com/" class="flex items-center">
        <svg style="margin-right: 0.5em;" xmlns="http://www.w3.org/2000/svg" 
                    class="iconx icon-tabler icon-tabler-cube-unfolded" 
                    width="32" height="32" 
                    viewBox="0 0 24 24" 
                    stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M2 15h10v5h5v-5h5v-5h-10v-5h-5v5h-5z"></path>
   <path d="M7 15v-5h5v5h5v-5"></path>
</svg>
        <span class="self-center text-2xl font-semibold whitespace-nowrap text-gray-300 dark:text-white">
            Metatext
        </span>
    </a>
    </div>
              <!-- <small class="text-xs	text-blue-500 tracking-tighter">BETA</small> -->
            
            </router-link>
          </div>
          <div class="text-sm">
            <a href="/doc/terms-of-service" class="text-gray-200 hover:text-gray-500 hover:underline transition duration-150 ease-in-out">
              Terms
            </a> · 
            <a href="/doc/privacy-policy"  class="text-gray-200 hover:text-gray-500 hover:underline transition duration-150 ease-in-out">
              Privacy
            </a> · 
            <router-link to="/about" class="text-gray-200 hover:text-gray-500 hover:underline transition duration-150 ease-in-out">
              About Us
            </router-link>
          </div>
        </div>

        <!-- 2nd block -->
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <h6 class="text-gray-500 font-medium mb-2">Our products</h6>
          <ul class="text-sm">
            <li class="mb-2">
              <a href="/" class="text-gray-200 hover:text-gray-500 transition duration-150 ease-in-out">AI Safety Platform</a>
            </li>

            <li class="mb-2">
              <a href="/finetune" class="text-gray-200 hover:text-gray-500 transition duration-150 ease-in-out">Finetune & Inference</a>
            </li>
          </ul>
        </div>

        <!-- 3rd block -->
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <h6 class="text-gray-500 font-medium mb-2">Datasets</h6>
          <ul class="text-sm">
            
            <li class="mb-2">
              <a href="/datasets#" class="text-gray-200 hover:text-gray-500 transition duration-150 ease-in-out">Curated Datasets</a>
            </li>
            <!-- <li class="mb-2">
              <a href="/datasets-list/portuguese-language" class="text-gray-200 hover:text-gray-500 transition duration-150 ease-in-out">Portuguese Datasets</a>
            </li>
            <li class="mb-2">
              <a href="/datasets-list/text-classification-task" class="text-gray-200 hover:text-gray-500 transition duration-150 ease-in-out">Text Classification Datasets</a>
            </li> -->
          </ul>
        </div>

        <!-- 4th block -->
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <h6 class="text-gray-500 font-medium mb-2">Resources</h6>
          <ul class="text-sm">
            <li class="mb-2">
              <a href="/blog" class="text-gray-200 hover:text-gray-500 transition duration-150 ease-in-out">Blog</a>
            </li>
            <li class="mb-2">
              <a href="/use-cases" class="text-gray-200 hover:text-gray-500 transition duration-150 ease-in-out">Use Cases</a>
            </li>
            <!-- <li class="mb-2">
              <a href="/tags" class="text-gray-200 hover:text-gray-500 transition duration-150 ease-in-out">Tags</a>
            </li> -->
            <li class="mb-2">
              <a href="https://discord.gg/PDWss5Fz79" target="_blank"  class="text-gray-200 hover:text-gray-500 transition duration-150 ease-in-out">Community</a>
            </li>
            <!-- <li class="mb-2">
              <a href="/apis" class="text-gray-200 hover:text-gray-500 transition duration-150 ease-in-out">Best APIs for NLP</a>
            </li>
            <li class="mb-2">
              <a href="/models" class="text-gray-200 hover:text-gray-500 transition duration-150 ease-in-out">Models for NLP</a>
            </li>
            <li class="mb-2">
              <a href="/datasets" class="text-gray-200 hover:text-gray-500 transition duration-150 ease-in-out">Open NLP Datasets</a>
            </li> -->
            
            
          </ul>
        </div>

        <!-- 5th block -->
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-3">
          <h6 class="text-gray-500 font-medium mb-2">Account</h6>
          
          
          <ul class="text-sm">

            <li class="mb-2">
                 <a href="/book-intro-call"
                 target="_blank"
                 class="text-gray-200 hover:text-gray-500 transition duration-150 ease-in-out">
                    Contact Us
                  </a>
               
            </li>


            <!-- <li class="mb-2">
                 <a href="/signup" 
                 class="text-gray-200 hover:text-gray-500 transition duration-150 ease-in-out">
                    Sign Up
                  </a>
               
            </li> -->

            <li class="mb-2">
                 <a href="/signin" 
                 class="text-gray-200 hover:text-gray-500 transition duration-150 ease-in-out">
                    Login
                  </a>
               
            </li>
          </ul>
                        
               
        </div>

      </div>

      <!-- Bottom area -->
      <div class="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-900 border-opacity-50">

        <!-- Social links -->
        <ul class="flex mb-4 md:order-1 md:ml-4 md:mb-0">
          <li>
            <!-- open link new tab -->
            
            <a href="https://github.com/metatext" target="_blank">
            <router-link class="flex justify-center items-center text-gray-200 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Github">
                <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" />
              </svg>
            </router-link>
            </a>
          </li>
          <!-- <li class="ml-4">
            <router-link to="#" class="flex justify-center items-center text-gray-200 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Github">
              <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" />
              </svg>
            </router-link>
          </li>
          <li class="ml-4">
            <router-link to="#" class="flex justify-center items-center text-gray-200 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Linkedin">
              <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
              </svg>
            </router-link>
          </li> -->
        </ul>

        
        <div class="text-sm text-gray-200 mr-4">&copy; 2024 Metatext. All rights reserved.</div>

      </div>

    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
}
</script>
