<template>
    <div>
  
      <!-- Site header -->
      <SiteHeader />
  
      <!-- Page content -->
      <main>
  
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
        <div class="pt-32 pb-12 md:pt-40 md:pb-20">
            
          
        <div
            v-for="(section, index) in Object.keys(entries)" :key="index">
            <div v-if="session == 'all' || section == session">
                                
                                        
            <h2 class="h5">{{section | capitalizeAll }}</h2>

        <!-- Articles list -->
        <div class="max-w-sm mx-auto md:max-w-none">
                    
                    <!-- Articles container -->
                    <div class="grid gap-12 md:grid-cols-3 md:col-gap-6 md:row-gap-8 items-start">
        
                    <article
                        class="items-center py-4 border-b border-gray-200"
                        v-for="entry in entries[section]" :key="entry.id">
                        <div>
                        <header>
            
            <router-link :to="`/${section}/${entry.id}`" tag="a">
                <figure class="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                        <img class="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                        :src="`https://placehold.co/600x400/${colors[section]}/white?font=open-sans&text=${0 ? entry.icon : entry.title.split(' ').slice(0, 4).join(' ')}`" width="352" height="198" :alt="entry.title" />
                    </figure>
          <div class="">
            <h3>
              {{entry.title}}
            </h3>
            <span class="subtitle">{{entry.date}}</span>
            <p>{{entry.description}}</p>
          </div>
          </router-link>
        
        </header>
        </div>
        </article>
    </div>


</div>

</div></div>


<a class="btn text-blue bg-white w-full mb-4 sm:w-auto sm:mb-0">
                    Scroll down to load more  
                </a>

    </div>
    </div>
  
      </main>
  
      <!-- Site footer -->
      <Footer />
  
    </div>
  </template>
  
  
  <script>
  import SiteHeader from './../partials/Header.vue'
  import Footer from './../partials/Footer.vue'
  import Contents from '@/statics/contents.json'

  export default {
    name: 'BlogPost',
    props: {
        session: {
            type: String,
            default: 'all'
        }
    },
    data() {
      return {
        session_name: 'blog',
        search: null,
        listPages: [],
        path: "",
        componentName: "Test",
        list: [],
        offset: 0,
        perPage: 10,
        apps: [],
        appList: [],
        size: 0,
        colors: {
            'blog': '0070F4',
            'use-cases': 'FF0080',
            'tutorials': 'FFA500',
            'doc': '444', // dark gray
            'tags': 'FFD700'
        },
        }
    },
    components: {
      SiteHeader,
      Footer,
    },
    beforeMount() {
        this.initialApp();
    },
    computed: {
        entries() {
            return Contents
        }
    },
    filters: {
        capitalize: function (value) {
          if (!value) return ''
          value = value.toString()
          return value.charAt(0).toUpperCase() + value.slice(1)
        },
        capitalizeAll: function (value) {
          if (!value) return ''
          value = value.toString()
          return value.split('-').map(function(item) {
            return item.charAt(0).toUpperCase() + item.slice(1)
          }).join(' ')
        }
    },

    beforeRouteLeave (to, from, next) {
        if (to.meta.componentName === 'Page') {
        this.loadRoute(to.path, next)
        } else {
        next()
        }
    },
    beforeRouteUpdate (to, from, next) {
        this.loadRoute(to.path, next)
    },
    created(){
        this.appList = this.searchApp()
    },
    mounted() {
        this.getNextUser();
        this.session_name = this.$route.path.split('/')[1]
    },
    methods: {
        searchApp(){
            return this.entries
        },
        changeSearch(){
            this.offset = 0
            this.list = []
            this.loadMoreApp()
        },
        loadMoreApp(){
            this.appList = this.searchApp()
            this.size = this.entries.length
            
            let j = 0
            if (this.offset > this.size){
                this.offset = this.size
            }
            for (let i = this.offset; i<= this.offset+this.perPage;i++ ){
                this.list.push(this.appList[i]);
                j += 1
            }
            
            this.offset += j
        },
        initialApp(){
            
            this.loadMoreApp()
            
        },
        getNextUser() {
            window.onscroll = () => {

                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                if (bottomOfWindow) {
                    this.loadMoreApp()
                }
            }
        },
        capitalize(i){
              return i.charAt(0).toUpperCase() + this.slice(1);
        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .blog {
    max-width: 50vw;
    margin: 6rem auto;
  
    .back {
      background-color: #42b883;
      color: white;
      margin-top: 5rem;
      text-decoration: none;
      padding: 10px 15px;
      border: 1px solid currentColor;
      border-radius: .5rem;
      display: inline-block;
      transition: all .3s ease;
      &:hover {
        background-color: transparent;
        color: #42b883;
      }
    }
    
      h1 {
        font-size: 3rem;
        margin-bottom: .2rem;
        color: #42b883;
      }
      h4 {
        margin-bottom: 3rem;
        color: #35495e;
      }
      pre {
        overflow-x: auto;
        background-color: #35495e;
        color: black;
        border-radius: .3rem;
        padding: 1rem;
      }
      img {
        max-width: 100%;
      }
    }
  
  </style>