<template>
  


    <!-- Docs nav -->
    <nav class="md:block" :class="{ 'hidden' : !sidebarNavOpen }">
    <ul class="font-medium -my-2">
        <span>See more APIs by {{ col }}</span>
        
        <li class="py-2" v-for="l in data[col]" :key="l">
        <a class="flex items-center hover:underline" :href="'/apis-list/'+l.replace(/\s+/g, '-').replace('(','').replace(')','').replace(':','').toLowerCase()+'-'+col">
            <div class="flex items-center flex-grow">
            <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 4H4c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1zM12 10H1c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1zM15 16H4c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1z" />
            </svg>
            <span>{{ l }} APIs</span>
            </div>
        </a>
        </li>

    </ul>
    </nav>

</template>

<script>


export default {
  name: 'NavAPIs',
		data () {
			return {
                 props: ['col'],
            
            sidebarNavOpen: false,
            sidebarLinkOpen: true,
            data: {

            'Category':
            [  'Sentiment',
       'Nutrition',  'Framework', 'Q&A', 'Text',
       'Translation', 'Business', 'Healthcare', 'Cryptocurrency',
       'Dictionary', 'Analytics', 'Semantics', 'Documents',
       'Bots', 'Language', 'Extraction', 'Education', 'Visualizations',
       'Summary', 'Travel', 'Food', 'Text-to-Speech', 
       'Feeds', 'Voice', 'Augmented Reality', 'Audio', 'OCR',
       'Names', 'Search', 'Lists', 'Content', 'Cloud',
       'Customer Service', 'Community', 'Chat', 'Location',
        'Writing', 'Calendars', 'Images',  'Tagging', 'Social', 'Recognition', 
       'Keywords','Transcription', 'Recommendations', 'All'],
            }
        }
        },
        props: {
            col: String
        }
	}
</script>
