<template>
    <section>
      <div class="max-w-6xl mx-auto px-4 sm:px-6">
        <div class="pt-32 pb-12 md:pt-40 md:pb-20">
  
          
        <!-- Page header -->
        <div class="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
          <h1 class="h1">Explore Pre-trained models</h1>
        </div>
        
        <!-- Search form -->
        <form class="mb-4 pb-4 border-b border-gray-200">
        <div class="flex flex-wrap">
            <div class="w-full">
            <label class="block text-sm sr-only" for="search">Search</label>
            <div class="relative flex items-center">
                <input @input="changeSearch" v-model="search" id="search" type="search" class="form-input w-full text-gray-800 px-3 py-2 pl-10" placeholder="Search the tools" />
                <button type="x" class="absolute inset-0 right-auto" aria-label="Search">
                <svg class="w-4 h-4 fill-current text-gray-400 mx-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zM15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                </svg>
                </button>
            </div>
            </div>
        </div>
        </form>

        
        
          <!-- Articles list -->
        <div class="max-w-sm mx-auto md:max-w-none">
            
            <!-- Articles container -->
            <div class="grid gap-12 md:grid-cols-3 md:col-gap-6 md:row-gap-8 items-start">
  
                
              <article
                class="items-center py-4 border-b border-gray-200"
                v-for="(page, i) of list" :key="i">
                <div>
                  <header>
                    <router-link :to="`/models/${page.key}`" class="block mb-6">
                    <figure class="relative h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                        <img class="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                        :src="`${page.img_url}`" width="352" height="198" alt="News 01" />
                    </figure>
                    </router-link>
                    
                    <router-link :to="'/models/'+page.key" class="block flex-shrink-0 ml-6">
                        <h2 class="h4 mb-2">
                            {{ page.title }}
                        </h2>
                        <!-- {{ page.tag }} -->
                    </router-link>
                  </header>
                </div>
                
              </article>
              
              
                <a class="btn text-blue bg-white w-full mb-4 sm:w-auto sm:mb-0">
                    Loaded {{ offset+1 }} out of {{ size }}<br>
                    Scroll down to load more  
                </a>
  
            </div>
  
          </div>
  
        </div>
      </div>
    </section>
  </template>
  
  <script>

  export default {
    name: 'PageList',
    data () {
              return {
                search: null,
                listPages: [],
                path: "",
                componentName: "Test",
                list: [],
                offset: 0,
                perPage: 10,
                apps: [],
                appList: [],
                size: 0
          }
    },
    beforeMount() {
        this.initialApp();
    },
    mounted() {
        this.getNextUser();
    },
    computed: {
      pageView(){
        
        var view = () => import(`./Single.vue`)
        
        return view
      }
    },
    beforeRouteLeave (to, from, next) {
        if (to.meta.componentName === 'Page') {
        this.loadRoute(to.path, next)
        } else {
        next()
        }
    },
    beforeRouteUpdate (to, from, next) {
        this.loadRoute(to.path, next)
    },
    created(){
        this.appList = this.searchApp()

        document.title = "Explore Pre Trained Models - Metatext.AI"
    },
    methods: {
        searchApp(){
            
            if (this.search != undefined && this.search.length > 0){
                return this.apps.filter(x => x.title.toLocaleLowerCase().includes(this.search.toLocaleLowerCase()))
            } else {
                return this.apps
            }
        },
        changeSearch(){
            this.offset = 0
            this.list = []
            this.loadMoreApp()
        },
        loadMoreApp(){
            this.appList = this.searchApp()
            this.size = this.appList.length
            
            let j = 0
            if (this.offset > this.size){
                this.offset = this.size
            }
            for (let i = this.offset; i<= this.offset+this.perPage;i++ ){
                this.list.push(this.appList[i]);
                j += 1
            }
            
            this.offset += j
        },
        initialApp(){
            
            this.$store.dispatch("getModels").then((data) => {

                this.apps = data
                this.appList = data
                this.size = this.appList.length

                this.loadMoreApp()
            })
            
        },
        getNextUser() {
            window.onscroll = () => {

                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                if (bottomOfWindow) {
                    this.loadMoreApp()
                }
            }
        },
        capitalize(i){
              return i.charAt(0).toUpperCase() + this.slice(1);
        },
        loadRoute(path, callback) {
  
            var p = path.split('/')
            this.path = p[p.length-1]
            var componentName = this.path
            
            if (this.path == "test"){
                componentName = "Test"
            }
  
            if (typeof callback === 'function') {
                callback(componentName)
            }
        },
        filterName(data, p_str){
  
                  // iterate over each element in the array
                  for (var i = 0; i < (data.length-1); i++){
                      // look for the entry with a matching `code` value
                      if ( (String(data[i].name.replace(/\s+/g, '-').toLowerCase()) == String(p_str.toLowerCase())) ){
                      // we found it
                      // obj[i].name is the matched result
                      // console.log(data[i])
                      
                      this.page = {
                          title: data[i].title,
                          content: data[i].description
                      }
  
  
                      break;
  
                      // this.content = data[i]
                      }
                  }
              }
    }
  
  }
  </script>