<template>
    <section class="relative">
  
      <!-- Social icons illustrations -->
      <!-- <div class="absolute left-1/2 transform -translate-x-1/2 bottom-0 mb-20 pointer-events-none" aria-hidden="true">
        <svg width="1360" height="365" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs>
            <filter x="-50%" y="-25%" width="200%" height="200%" filterUnits="objectBoundingBox" id="scill-sh">
              <feMorphology radius="2.5" in="SourceAlpha" result="shadowSpreadOuter1" />
              <feOffset dy="20" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
              <feGaussianBlur stdDeviation="12.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
              <feColorMatrix values="0 0 0 0 0.0980392157 0 0 0 0 0.0980392157 0 0 0 0 0.0980392157 0 0 0 0.16 0" in="shadowBlurOuter1" />
            </filter>
            <circle id="scill-bc" cx="40" cy="40" r="40" />
            <circle id="scill-sc" cx="32" cy="32" r="32" />
            <circle id="scill-xsc" cx="28" cy="28" r="28" />
          </defs>
          <g fill="none" fill-rule="evenodd">
            <g transform="rotate(-19 110.5 -381.291)">
              <use fill="#000" filter="url(#scill-sh)" xlink:href="#scill-bc" />
              <use fill="#1977F2" xlink:href="#scill-bc" />
            </g>
            <path d="M182.538 55.335l-4.232-12.292-4.219 1.453-1.953-5.674 4.219-1.452-1.364-3.962c-1.486-4.316.514-7.38 4.364-8.705 1.844-.635 3.476-1.044 3.96-1.141l1.552 4.51-2.67.92c-2.093.721-2.223 1.879-1.72 3.339l1.114 3.236 5.673-1.954.062 6.325-3.782 1.302 4.233 12.292-5.237 1.803z" fill="#FFF" fill-rule="nonzero" />
            <g transform="rotate(20 -85.403 3424.487)">
              <use fill="#000" filter="url(#scill-sh)" xlink:href="#scill-bc" />
              <use fill="#333" xlink:href="#scill-bc" />
            </g>
            <path d="M1195.472 271.965c-8.269-3.01-17.497 1.293-20.507 9.563-2.428 6.671-.158 13.883 5.044 18.011.717.368 1.136.094 1.307-.376l.924-2.537c-4.571-.6-4.357-3.82-4.357-3.82-.042-1.93-.904-2.777-.904-2.777-1.068-1.453.436-.905.436-.905 1.469.64 1.802 2.358 1.802 2.358.494 2.734 2.895 2.863 3.972 2.83a3.452 3.452 0 011.658-1.632c-3.247-1.607-6.245-4.188-4.158-9.92.581-1.598 1.658-2.802 2.974-3.494-.051-.444.026-2.119 1.624-3.878 0 0 1.359.069 3.588 3.008 1.358.069 2.614.42 3.93.899 1.315.478 2.468 1.11 3.587 1.837 3.632-.913 4.716-.092 4.716-.092.094 2.375-1.017 3.673-1.248 4.015.563 1.376.648 2.896.033 4.588-2.087 5.732-6.042 5.782-9.562 4.927.393.675.52 1.785.008 3.195l-1.505 4.135c-.137.376-.026.948.76 1.128 6.638.181 13.013-3.884 15.441-10.556 3.01-8.269-1.293-17.497-9.563-20.507z" fill="#FFF" />
            <g transform="rotate(29 -350.439 475.37)">
              <use fill="#000" filter="url(#scill-sh)" xlink:href="#scill-sc" />
              <use fill="#E60123" xlink:href="#scill-sc" />
            </g>
            <path d="M204.818 262.505c-5.773-3.2-13.114-1.095-16.313 4.677-2.473 4.46-1.759 9.773 1.217 13.48.349-.835.988-2.195 1.648-2.973.611-.69 4.133-4.57 4.133-4.57s.126-.844.61-1.719c.825-1.487 2.281-2.051 3.33-1.47.875.485.925 1.427.489 2.214-.485.875-1.873 1.935-2.814 3.014-.844.904-.542 2.215.507 2.797 1.837 1.018 4.39-.082 5.99-2.968 1.406-2.537.54-5.304-1.997-6.71-2.974-1.648-5.983-.344-7.244 1.93-.485.875-.668 2.03-.522 2.798.04.136-.01.223-.058.31-.281.302-.844.905-.94 1.08-.185.126-.321.165-.448-.02-.972-1.339-.693-3.7.131-5.186 1.843-3.324 5.988-5.027 10.449-2.555 3.673 2.036 5.018 6.212 3.127 9.623-1.988 3.586-5.91 5.299-9.059 3.554-1.05-.582-1.808-1.689-1.77-2.582 0 0-1.64 1.72-2.018 2.197-.747.729-1.99 1.526-2.815 1.984.73.747 1.682 1.504 2.731 2.085 5.773 3.2 13.114 1.095 16.313-4.677 3.2-5.773 1.095-13.114-4.677-16.313z" fill="#FFF" fill-rule="nonzero" />
            <g>
              <g transform="rotate(-16 972.557 -3949.915)">
                <use fill="#000" filter="url(#scill-sh)" xlink:href="#scill-sc" />
                <use fill="#1CA1F2" xlink:href="#scill-sc" />
              </g>
              <path d="M1175.413 126.29c-.755.633-1.538 1.17-2.471 1.541.796-.852 1.289-2.034 1.37-3.201-.796.852-1.647 1.512-2.649 2.008a4.841 4.841 0 00-3.901-.546 4.908 4.908 0 00-3.36 6.06c.11.385.22.77.4 1.03a13.807 13.807 0 01-11.239-2.187c-.26.907-.232 1.731.016 2.597.469 1.634 1.748 2.828 3.245 3.334-.769.22-1.593.25-2.28.03l.028.096c.661 2.307 2.847 3.761 5.072 3.54-.357.206-.714.412-1.195.55-.288.082-.577.165-.893.152 1.128 1.757 3.245 2.606 5.36 2-1.276 1.718-3.075 3.066-5.285 3.7-.385.11-.77.22-1.182.235 2.501.74 5.22.791 7.816.047 8.748-2.508 11.39-11.068 9.6-17.316l-.166-.577c.768-.949 1.289-2.034 1.714-3.092z" fill="#FFF" fill-rule="nonzero" />
            </g>
            <g>
              <g transform="rotate(26 -278.852 269.93)">
                <use fill="#000" filter="url(#scill-sh)" xlink:href="#scill-xsc" />
                <use fill="#0080CA" xlink:href="#scill-xsc" />
              </g>
              <path d="M116.35 180.16l-16.179-7.89c-1.528-.746-3.266-.148-4.011 1.38l-7.89 16.179c-.746 1.528-.148 3.266 1.38 4.011l16.179 7.89c1.528.746 3.266.148 4.011-1.38l7.89-16.179c.746-1.528.148-3.266-1.38-4.011zm-17.778 11.356a2.006 2.006 0 01-2.674.92l-2.697-1.314a2.006 2.006 0 01-.92-2.675l5.698-11.684a2.006 2.006 0 012.675-.92l2.696 1.314a2.006 2.006 0 01.921 2.675l-5.699 11.684zm12.517-.57a2.006 2.006 0 01-2.674.92l-2.697-1.315a2.006 2.006 0 01-.92-2.674l3.068-6.292a2.006 2.006 0 012.675-.92l2.696 1.314a2.006 2.006 0 01.92 2.675l-3.068 6.291z" fill="#FFF" fill-rule="nonzero" />
            </g>
            <g>
              <g transform="rotate(17 176.891 4319.928)">
                <use fill="#000" filter="url(#scill-sh)" xlink:href="#scill-sc" />
                <circle stroke="#EAEAEA" stroke-linejoin="square" fill="#FFF" cx="32" cy="32" r="31.5" />
              </g>
              <path d="M1303.077 166.793l-8.62 2.42 5.814 6.758 8.601-2.35-5.795-6.828zm-14.413-4.407l-8.621 2.42 5.815 6.759 8.599-2.352-5.793-6.827zm2.987 16.006l5.793 6.827 8.622-2.42-5.795-6.828-8.62 2.42zm-14.415-4.407l5.795 6.827 8.62-2.42-5.793-6.827-8.622 2.42zm5.337 8.324l5.793 6.826 8.62-2.42-5.814-6.758-8.599 2.352z" fill="#0062FF" />
            </g>
          </g>
        </svg>
      </div> -->
  
      <div class="max-w-6xl mx-auto px-4 sm:px-6">
        <div class="py-12 md:py-16">
  
          <!-- Section header -->
          <div class="max-w-4xl mx-auto text-center pb-12 md:pb-16">
            <h2 class="h2 mb-4" data-aos="zoom-y-out">
                Trusted by +6,000 teams all over the world
            </h2>
            <!-- <p class="text-xl text-gray-600">Arcu cursus vitae congue mauris rhoncus viverra nibh cras pulvinar mattis
              blandit libero cursus mattis.</p> -->
          </div>
  
          <!-- Items-->
          <!-- <div class="max-w-sm md:max-w-4xl mx-auto grid gap-2 grid-cols-4 md:grid-cols-4">
  
            
            <div class="flex items-center justify-center py-2 col-span-2 md:col-auto">
              <img class="w-50 h-8 object-cover mr-3"
                src="https://upload.wikimedia.org/wikipedia/commons/8/89/EUSPA_Logo.png">
            </div>
  
            <div class="flex items-center justify-center py-2 col-span-2 md:col-auto">
              <img class="w-50 h-5 object-cover mr-3"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/50/Nestle_textlogo_blue.svg/1200px-Nestle_textlogo_blue.svg.png">
              
            </div>
  
  
            <div class="flex items-center justify-center py-2 col-span-2 md:col-auto">
              <img class="w-50 h-7 object-cover mr-3"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Gov.br_logo.svg/1200px-Gov.br_logo.svg.png">
            </div>
  
  
            <div class="flex items-center justify-center py-2 col-span-2 md:col-auto">
              <img class="w-50 h-5 object-cover mr-3"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Deloitte.svg/1280px-Deloitte.svg.png">
            </div>
  
            
  
          </div>  -->
  
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import Image01 from '../images/tutorial-01.jpg'
  import Image02 from '../images/tutorial-02.jpg'
  import Image03 from '../images/tutorial-03.jpg'
  
  export default {
    name: 'TestimonialsCarousel',
    data: function () {
      return {
        active: 0,
        autorotate: true,
        autorotateTiming: 7000,
        items: [
          {
            img: Image01,
            alt: 'Testimonial 01',
            quote:
              '“ I love this product and would recommend it to anyone. Could be not easier to use, and our multiple websites are wonderful. We get nice comments all the time. “',
            name: 'Darya Finger',
            role: 'CEO & Co-Founder',
            team: '@Dropbox',
            link: '#0'
          },
          {
            img: Image02,
            alt: 'Testimonial 02',
            quote:
              '“ Not only in my career but in education as well, I feel grateful that Simple pushed me to expand my horizons and strive to accomplish more.“',
            name: 'Greg Sebastian',
            role: 'Head of Design',
            team: '@Shopify',
            link: '#0'
          },
          {
            img: Image03,
            alt: 'Testimonial 03',
            quote:
              '“ I feel grateful that Simple pushed me to expand my horizons and strive to accomplish more, not only in my career but in education as well.“',
            name: 'Dominik Prasad',
            role: 'Backend Lead',
            team: '@Google',
            link: '#0'
          }
        ]
      }
    },
    methods: {
      stopAutorotate() {
        clearInterval(this.autorotateInterval)
      }  
    },    
    mounted() {
      if (this.autorotate) {
          this.autorotateInterval = setInterval(() => {
              this.active = this.active + 1 === this.items.length ? 0 : this.active + 1
          }, this.autorotateTiming)
      }
    },
    beforeUnmount() {
      this.stopAutorotate()
    }
  }
  </script>