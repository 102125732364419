<template>
  <section>
    <div class="mx-auto">
      <div class="pb-8 md:pb-8">


        <!-- CTA box -->
        <div class="bg-blue-600 rounded py-8 px-8 md:py-8 md:px-8 shadow-2xl">

        <div class="flex flex-col lg:flex-row justify-between items-center">

            <!-- CTA content -->
            <div class="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left">
            <h3 class="h4 text-white mb-2">
                Classify and extract text 10x better and faster 🦾
            </h3>
            <p class="text-white text-lg opacity-75">
                Metatext helps you to classify and extract information from text and documents with customized language models with your data and expertise.
                <b>
                    <a href="https://metatext.io?resources" target="_blank">
                    Try it for free!
                    </a>
                </b>
            </p>
            <a class="btn text-blue-600 bg-gradient-to-r from-blue-100 to-white " href="https://metatext.io?resources" target="_blank">
            ➡️&nbsp;&nbsp;Learn more
            </a>
            </div>
        </div>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'CtaAlternative',
}
</script>