<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pb-12 md:pb-20">

        <!-- CTA box -->
        <div class="bg-gray-900 rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl" data-aos="zoom-y-out">

          <div class="flex flex-col lg:flex-row justify-between items-center">

            <!-- CTA content -->
            <div class="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left lg:w-1/2">
              <h2 class="h2 text-white">
                Classify and extract text 10x better and faster 🦾
              </h2>
              <p class="text-white">
                Metatext helps you to classify and extract information from text and documents with customized language models with your data and expertise.
              </p>
            </div>

            <!-- CTA button -->
            <div>
                <a class="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0" href="/book-intro-call">
                    Book a demo
                </a>
            </div>

          </div>

        </div>

      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'CtaAlternative',
}
</script>