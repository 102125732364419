<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
    <component v-bind:is="pageView"></component>
       
    <Cta />      

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import Cta from './../partials/CtaAlternative.vue'
import TextClassification from '../partials/contents/products/classification.vue'
import Footer from './../partials/Footer.vue'

export default {
  name: 'Models',
  components: {
    Header,
    Cta,
    TextClassification,
    Footer
  },
  created(){
      //document.title = "Text Classification API - Metatext"
  },
  computed: {
    pageView(){
      var path = this.$route.path.split('/')
      path = path[path.length-1]

      document.title = `${path} API - Metatext`
      var productName = "classification"
      var view = () => import(`../partials/contents/products/${productName}.vue`)
      
      try{
        require(`../partials/contents/products/${productName}.vue`)
      } catch {
        view = () => import(`../partials/contents/ContentNotFound.vue`)
      }
      return view
    }
  },
}
</script>
