<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <section>
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <div class="pt-32 pb-12 md:pt-40 md:pb-20">

            <!-- Main content -->
            <div class="md:flex md:justify-between" data-sticky-container>

              <!-- Sidebar -->
              <aside class="relative my-12 md:my-0 md:w-64 md:mr-12 lg:mr-20 md:flex-shrink-0">
                <div data-sticky data-margin-top="100" data-sticky-for="768" data-sticky-wrap>

                  <!-- Search form -->
                  <form class="mb-4 pb-4 border-b border-gray-200">
                    <div class="flex flex-wrap">
                      <div class="w-full">
                        <label class="block text-sm sr-only" for="search">Search</label>
                        <div class="relative flex items-center">
                          <input id="search" 
                          type="search" 
                          class="form-input w-full text-gray-800 px-3 py-2 pl-10" 
                          placeholder="Search the docs" 
                          v-model="page"/>
                          <button type="submit" class="absolute inset-0 right-auto" aria-label="Search">
                            <svg class="w-4 h-4 fill-current text-gray-400 mx-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zM15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>

                  <button class="flex items-center justify-between text-lg font-medium text-gray-900 w-full my-4 md:hidden">
                    <span>Navigation</span>
                    <svg class="w-3 h-3 fill-current text-blue-600 flex-shrink-0 ml-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <rect y="7" width="16" height="2" rx="1" class="transform origin-center transition duration-200 ease-out"/>
                      <rect y="7" width="16" height="2" rx="1" class="transform origin-center rotate-90 transition duration-200 ease-out" />
                    </svg>
                  </button>

                  <NavDatasets :col="page"/>

                </div>
              </aside>

              <!-- Page container -->
              
          <div class="md:flex-grow">
                <Cta />
                <div class="text-lg text-gray-600">
                  <h1 class="h1 text-gray-900 mb-4">
                    {{ filter }} {{ page2 }} Resources for Natural Language Processing Projects
                  </h1>

                  <p>
                    This is a complete list of resources about {{ filter }} {{ page2 }} 
                    for your next project in natural language processing.
                  </p>
                  

                  <!--h2>
                    What is {{ filter }} {{ page }}?
                  </h2>
                  <p>
                    {{ filter }} are {{ page }} of Natural Language Processing area.
                  </p-->

                  <br>

                  <h3 class="h3 text-gray-900 mb-8" 
                  style="scroll-margin-top: 100px;">
                    Found {{ contents.length}} {{ filter }}
                    </h3>
                  <p>
                    <b>Let’s get started!</b>
                  </p>

    <!-- Related content -->
    <div class="mt-8">
      <!--h3 class="h3 mb-8">Related</h3-->
      <span v-for="content in contents" :key="content.id">
      <a class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" 
      :href="content.url">
        <div>
          <div class="text-normal font-medium mb-1">
            {{ content.title }}
            <span class="px-2 inline-flex text-xs 
            leading-5 font-semibold rounded-full 
            bg-green-200 text-green-800" v-if="content.type == 'dataset'">
              Dataset
            </span>
            <span class="px-2 inline-flex text-xs 
            leading-5 font-semibold rounded-full 
            bg-blue-200 text-blue-800" v-if="content.type == 'model'">
              Model
            </span>
          </div>
          <div class="text-sm text-gray-600">{{ content.body }}</div>
        </div>
        <svg class="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </a>
      </span>
    </div>
                  
                </div>

      <hr class="w-full h-px pt-px bg-gray-200 border-0 mt-8" />


              </div>

            </div>

          </div>
        </div>
      </section>

    
      
      <CtaAlternative />      

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import CtaAlternative from './../partials/CtaAlternative.vue'
import Footer from './../partials/Footer.vue'
import NavDatasets from './../partials/NavHub.vue'
import json_datasets from './../assets/json/datasets_20201223.json'
import json_items from './../assets/json/hub-items.json'
import json_models from './../assets/json/models_2021-03-28.json'
import Cta from './../partials/CtaHub.vue'

export default {
  props: ["level1", "level2"],
  name: 'Hub',
		data () {
			return {
            ready: false,
            contents: [],
            page: '',
            page2: '',
            filter: '',
            item: '',
            v: '',
            sidebarNavOpen: false,
            sidebarLinkOpen: true,
            task_description: {
              'Classification': 'Text Classification is the process of assigning the text to classes or categories based on predefined features such as words or sentences.',
              'Summarization': 'Summarization is a natural language processing (NLP) task that requires, given a document of arbitrary length, a summarizer to return a shorter, relevant subset of the input for a specific purpose.',
              'Translation': 'Language translation, or machine translation, is the use of computer software to translate text from a document in one language to text in another language.',
              'Ner': 'Named Entity Recognition (NER), is the process of converting unstructured text (text without the use of a markup language) into an annotated ontology leveraging a deep understanding of a specific domain (e.g., Medicine, Finance, etc) and language (e.g., English, Chinese, etc).',
              'Corpora': 'NLP corpora datasets is a type of structured learning data that contains texts in various domains.',
              'Sentiment analysis': 'Sentiment analysis is the process of understanding how the writer or speaker’s sentiment affects the tone of a given piece of text.',
              'Speech': 'Speech recognition is a natural language processing (NLP) task that listens to and comprehends human speech. Speech Recognition interprets the spoken words of a natural language such as English as computer commands or data input.',
              'Embeddings': 'Word embeddings is a set of techniques in natural language processing that is typically used for building contextually-sensitive word representation vectors.',
              'Generation': 'This is a sub-domain of Natural Language Processing (NLP). Text Generation converts natural language to natural language, such that the converted text is indistinguishable from that which was originally generated by a human writer. ',
            
            }
            }
        },
        components: {
            Header,
            CtaAlternative,
            Footer,
            NavDatasets,
            Cta
        },
        beforeRouteLeave (to, from, next) {
            if (to.meta.componentName === 'Page') {
            this.loadContents(to.path, next)
            } else {
            next()
            }
        },
        beforeRouteUpdate (to, from, next) {
            this.loadContents(to.path, next)
        },
        created(){
            this.loadContents(this.$route.path)
        },
        // mounted(){
        //     this.loadContents(this.$route.path)
        // },
        methods: {
            loadContents (path, callback) {
                
                
                
                var path_arr = path.split('/').slice(-2);//.split("-").join(" ")
                
                this.page = path_arr[0]
                var value = path_arr[1]

                

                this.topics = json_items[this.page]
                if (value === undefined){
                  this.contents = false
                  document.title = this.page +' - NLP Hub'
                  return
                }

                // this.content = response.data
                //this.filterBy(data, col, value.join(' ').replace('(','').replace(')','').replace(':',''))
                this.datasets = json_datasets
                this.models = json_models

                this.filterBy(value)
                // Shuffle array
                const shuffled = this.contents.sort(() => 0.5 - Math.random());

                // Get sub-array of first n elements after shuffled
                this.contents = shuffled.slice(0, this.contents.length);

                String.prototype.capitalize = function() {
                    return this.charAt(0).toUpperCase() + this.slice(1);
                }

                
                this.filter = String(value.replace('(','').replace(')','').replace(':','')).capitalize()
                this.filter = this.filter.split('-').join(' ')
                var count = this.contents.length || 0
                if (count && count > 2) {
                  count = ''+count+''
                } else {
                  count = ''
                }
                

                

                this.ready = true


                if (!["model-scope","hot-topics","lingual", "pipeline"].some(v => this.page.toLowerCase().includes(v))){
                  this.page2 = this.page.capitalize()
                }

                document.title = 'Top '+ count + ' resources for '+this.filter.toLowerCase() +' '+this.page2.toLowerCase()+' - NLP Hub'

                if (typeof callback === 'function') {
                    callback()
                }

                
            },
            check_item(data, col, value) {
                    let tmp_value = value
                    if (data === undefined){
                      return false
                    } else {
                      return (String(data[col].replace(/\s+/g, '-').toLowerCase()).includes(String(tmp_value.toLowerCase())))
                    }
            },
            filterBy(value){

                if (value == 'programming languages') {
                  value = "python"
                } else if ( value == 'sentiment analysis') {
                  value = "sentiment"
                } else if ( value == 'multi lingual') {
                  value = "multi"
                }

                var dataset_length = this.datasets.length
                var model_length = this.models.length
                var max_length = Math.max(dataset_length, model_length)

              
                // iterate over each element in the array
                for (var i = 0; i < (max_length-1); i++){
                    // look for the entry with a matching `code` value
                  

                  
                  if ( !this.page.includes(["models", "Model-scope", "architectures"]) && this.check_item(this.datasets[i], "dataset", value) ){
                      // "dataset" "description"
                        this.contents.push({
                            id: Math.floor(Math.random() * 111111111111111),
                            title: this.datasets[i]["dataset"],
                            body: this.datasets[i]["description"],
                            type: "dataset",
                            url: "/datasets/"+this.datasets[i].dataset.replace(/\s+/g, '-').toLowerCase()
                        })
                      
                  }
                  if ( !this.page.includes(["datasets"]) && this.check_item(this.models[i], "title", value) ){
                          // "title" "summarization" 
                          this.contents.push({
                              id: Math.floor(Math.random() * 111111111111111),
                              title: this.models[i]["title"],
                              body: this.models[i]["summarization"],
                              type: "model",
                              url: "/models/" + this.models[i]["title"].replace('/', '-').toLowerCase()
                          })
                  }

                }
                
              }
        }
	}
</script>
