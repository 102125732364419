<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Illustration behind content -->
      <div class="absolute left-1/2 transform -translate-x-1/2 -mb-64 bottom-0 pointer-events-none" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stop-color="#FFF" offset="0%" />
              <stop stop-color="#EAEAEA" offset="77.402%" />
              <stop stop-color="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fill-rule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>

      <div class="max-w-6xl mx-auto px-4 sm:px-6">
        <div class="pt-32 pb-12 md:pt-40 md:pb-20">
          <div class="max-w-3xl mx-auto text-center">
            <!-- 404 content -->
            <h1 class="h1 mb-4" data-aos="zoom-y-out" v-if="this.r">Redirecting in {{ s }} seconds</h1>
            <p class="mb-4" data-aos="zoom-y-out" v-html="this.description"></p>
            <br>
            
            <div class="mt-8">
              <button @click="$router.go(-1)" class="btn text-white bg-blue-600 hover:bg-blue-700">Go back dataset</button>
            </div>



          </div>

        </div>
      </div>

      <CtaAlternative />      

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import CtaAlternative from './../partials/CtaAlternative.vue'
import Footer from './../partials/Footer.vue'
import json_datasets from './../assets/json/datasets_20201223.json'

export default {
  name: 'Datasets',
		data () {
			return {
            ready: false,
            content: {},
            url: '',
            s: 6,
            description: '',
            r: true
            }
        },
        components: {
            Header,
            CtaAlternative,
            Footer
        },
        beforeRouteLeave (to, from, next) {
            if (to.meta.componentName === 'Page') {
            this.loadContents(to.path, next)
            } else {
            next()
            }
        },
        beforeRouteUpdate (to, from, next) {
            this.loadContents(to.path, next)
        },
        created(){
            this.loadContents(this.$route.path)
            if (this.content.download.includes("http://")) {
                this.r = false
                this.description = "Unsafe link. Download at your own risk here <br><a href=\""+this.content.download+"\">"+this.content.download+"</a>"
            } else {
                this.redirect()
            }
        },
        methods: {
            redirect() {
                this.s = this.s - 1
                if (this.s < 1){
                    if (this.content.download.includes("http://")) {
                        this.r = false
                        this.description = "Unsafe link. Download at your own risk here <br><a href=\""+this.content.download+"\">"+this.content.download+"</a>"
                    } else {
                        window.location = this.content.download
                    }
                } else {
                    window.setTimeout(this.redirect, 1000)
                }
            },
            loadContents (path, callback) {
                

                var p_str = path.split('/')[2]//.split("-").join(" ")

                var data = json_datasets


                // iterate over each element in the array
                for (var i = 0; i < (data.length-1); i++){
                    // look for the entry with a matching `code` value
                    if ( ((String(data[i].dataset.replace(/\s+/g, '-').toLowerCase()) == String(p_str.toLowerCase()) ))){
                    // we found it
                    // obj[i].name is the matched result                    
                    this.content = {
                        title: data[i].dataset,
                        tag: data[i],
                        download: data[i].download,
                        body: data[i].description,
                        pdf: "https://docs.google.com/gview?url=" + data[i].paper +"&embedded=true",
                    }

                    this.description = "Download "+ this.content.title +" dataset from external link"

                    document.title = data[i].dataset + ' dataset - Download from NLP Database'

                    break;
                    }
                    
                }
                

                //if (Array.isArray(data.body)) {
                //  this.content.body = data.body.join(" ")
                //}

                this.ready = true

                if (typeof callback === 'function') {
                    callback()
                }
            }
        }
	}
</script>
