<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
      <HeroHome />
      
      
      <!-- <Demo /> -->
      <FeaturesHome />
      <ExploreCases />
      <FeaturesWorld />
      <TestimonialsCarousel />
      <!-- <UseCases /> -->
      <PricingTables />
      
      
      
      <Credentials />
      
      
      
      <Faqs />
      <Cta />

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>

import Header from './../partials/Header.vue'
import HeroHome from './../partials/HeroHome.vue'
import PricingTables from './../partials/PricingTables.vue'
import ExploreCases from './../partials/UseCases.vue'
import Credentials from '../partials/Credentials.vue'
import FeaturesHome from '../partials/FeaturesHome.vue'

// import FeaturesHeader from '../partials/FeaturesHeader.vue'
// import Stats from './../partials/Team.vue' // credentials, news from Gartner etc
//import HowWorks from './../partials/HowWorks.vue' // adapt to how it works
// import Stats from './../partials/Team.vue' // credentials and depoiments
import TestimonialsCarousel from './../partials/TestimonialsCarousel.vue' // credentials, news from Gartner etc
import FeaturesWorld from './../partials/FeaturesWorld.vue'
//import News from './../partials/News.vue'
import Faqs from './../partials/Faqs.vue'
//import UseCases from './../partials/UseCases.vue'

//import Demo from '../partials/Demo.vue'

import Cta from './../partials/CtaAlternative.vue'
import Footer from './../partials/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    HeroHome,
    FeaturesHome,
    ExploreCases,
    FeaturesWorld,
    Credentials,
    TestimonialsCarousel,
    //UseCases,
    // FeaturesHeader,
    //HowWorks,
    PricingTables,
    Faqs,
    //Demo,
    Cta,
    Footer,
    // Stats
  },
  created(){
      document.title = this.$i18n.t(`title`)
  },
}
</script>
