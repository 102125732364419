<template>
  


    <!-- Docs nav -->
    <nav class="md:block" :class="{ 'hidden' : !sidebarNavOpen }">
    <ul class="font-medium -my-2">


        <li class="py-2" v-for="(val, key) in data" :key="key">
            <a class="flex items-center hover:underline"  
            @click.prevent="sidebarLinkOpen[key] = !sidebarLinkOpen[key]" 
            aria-haspopup="true" 
            :aria-expanded="sidebarLinkOpen[key]"
            :href="'/nlp/'+ key">
                <div class="flex items-center flex-grow">
                <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 3.294L1.4 1.035C1.1.847.7.941.4 1.13c-.2.189-.4.471-.4.753v10.353c0 .377.2.753.6.847L7 15.718V3.294zM15.6 1.13c-.3-.189-.6-.189-.9-.095L9 3.295v12.423l6.4-2.542c.4-.188.6-.47.6-.847V1.882c0-.282-.2-.564-.4-.753z" />
                </svg>
                <span>{{ key | capitalize}}</span>
                </div>
                <svg class="w-3 h-3 fill-current text-gray-400 cursor-pointer ml-1 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.28 4.305L5.989 8.598 1.695 4.305A1 1 0 00.28 5.72l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z" />
                </svg>
            </a>
            
            <ul class="font-normal -mb-1 mt-1 ml-2 pl-5 border-l border-gray-300" :class="{ 'hidden': !sidebarLinkOpen[key] }">
                <li class="py-1" v-for="j in val" :key="j">
                    <a v-if="j != key" class="text-gray-600 hover:underline" :href="'/nlp/'+ key+'/'+j.split(' ').join('-')">
                        {{ j | capitalize}}
                    </a>
                </li>
            </ul>
        </li>

    </ul>
    </nav>

</template>

<script>

import json_items from './../assets/json/hub-items.json'

export default {
  name: 'NavDatasets',
		data () {
			return {
                 props: ['col'],
                 filter: 'task',
            sidebarNavOpen: false,
            //sidebarLinkOpen: true,
            sidebarLinkOpen: {
            "datasets": false,
            "tasks": false,
            "domains": false,
            "models": false,
            "lingual": false,
            "model-scope": false,
            "architectures": false,
            "pipeline": false,
            "hot-topics": false
            }
        }
        },
        filters: {
            capitalize(text) {
                return text.charAt(0).toUpperCase() + text.slice(1);
            }
        },
        methods: {
            changeNav(col) {
                this.filter = col
            }
        },
        created() {
            this.filter = this.col | "datasets"
            this.data = json_items
            this.sidebarLinkOpen[this.col.toLowerCase()] = true
        },
        props: {
            col: String
        }
	}
</script>
