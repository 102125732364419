<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <section>
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <div class="pt-32 pb-12 md:pt-40 md:pb-20">

            <!-- Main content -->
            <div class="md:flex md:justify-between" data-sticky-container>

              <!-- Sidebar -->
              <aside class="relative my-12 md:my-0 md:w-64 md:mr-12 lg:mr-20 md:flex-shrink-0">
                <div data-sticky data-margin-top="100" data-sticky-for="768" data-sticky-wrap>

                  <!-- Search form -->
                  <div class="mb-4 pb-4 border-b border-gray-200">
                    <div class="flex flex-wrap">
                      <div class="w-full">
                        <a class="flex items-center hover:underline" href="/datasets">
                          <div class="flex items-center flex-grow">
                            
                            <span>Go back to search datasets</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <button class="flex items-center justify-between text-lg font-medium text-gray-900 w-full my-4 md:hidden">
                    <span>Datasets navigation</span>
                    <svg class="w-3 h-3 fill-current text-blue-600 flex-shrink-0 ml-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <rect y="7" width="16" height="2" rx="1" class="transform origin-center transition duration-200 ease-out"/>
                      <rect y="7" width="16" height="2" rx="1" class="transform origin-center rotate-90 transition duration-200 ease-out" />
                    </svg>
                  </button>

                  <NavDatasets :col="v"/>

                </div>
              </aside>

              <!-- Page container -->
              
          <div class="md:flex-grow">
                <!-- <CtaHub :task="filter"/> -->
                <div class="text-lg text-gray-600">
                  <h1 class="h1 text-gray-900 mb-4">
                    List of {{ filter }} Datasets for Machine Learning Projects
                  </h1>

                  <p v-if="v == 'language'">

                    <span v-if="filter != 'English' && filter != 'Chinese'">
                      High-quality datasets are the key to good performance in natural language processing (NLP) projects.
                    Although there are hard to find low resource language datasets, like {{ filter }} language, 
                    there a good list of them to you start your machine learning (ML) project right now.
                    To solve this, we collected a list of {{ filter }} NLP datasets for machine learning, <router-link to="/datasets" class="hover:underline;">a large curated base</router-link> for training data and testing data. 
                    
                    Covering a wide gamma of NLP use cases, from text classification, part-of-speech (POS), to machine translation. 
                    </span>
                    <span v-else>
                    High-quality datasets are the key to good performance in natural language processing (NLP) projects. 
                    We collected a list of {{ filter }} NLP datasets for machine learning, <router-link to="/datasets" class="hover:underline;">a large curated base</router-link> for training data and testing data. 
                    
                    Covering a wide gamma of NLP use cases, from text classification, part-of-speech (POS), to machine translation. 
                    </span>
                  </p>
                  <div v-if="v == 'task'">
                    <p class="mb-8">
                    High-quality datasets are the key to good performance in natural language processing (NLP) projects. 
                    We collected a list of NLP datasets for {{ filter }} task, to get started your machine learning projects. Bellow your find <router-link to="/datasets" class="hover:underline;">a large curated training base</router-link> for {{ filter }}.
                    </p>
                    
                    <h3 class="h3 text-gray-900 mb-8" 
                  style="scroll-margin-top: 100px;">
                  What is {{ filter }} task?
                    </h3>
                    <p class="mb-8">
                      {{ task_description[filter] }}
                    </p>
                  </div>
                  <br>
                  <h3 id="models" class="h3 text-gray-900 mb-4" style="scroll-margin-top: 100px;">
                    Custom fine-tune with {{ filter }} datasets
                  </h3>
                  <p class="mb-8">
                    Metatext is a powerful no-code tool for train, tune and integrate custom NLP models
                    <br>
                      <a href="https://metatext.ai?datasets-list" class="btn text-blue-600 bg-gradient-to-r from-blue-100 from-blue-100" target="_blank">
                      ➡️&nbsp;&nbsp;Try for free
                      </a>
                  </p>
                  
                  <!-- <p class="mb-8">
                    Metatext helps you to develop NLP models without code. 
                    Test it for free, and train or use a pre-trained {{ filter }} model in minutes.<br>
                    
                    <a href="/signup?datasetslist" class="btn text-blue-600 bg-gradient-to-r from-blue-100 to-white " target="_blank">
                    ➡️&nbsp;&nbsp;Sign Up Free
                    </a>
                  </p> -->
                  <br>
                  <h3 class="h3 text-gray-900 mb-8" 
                  style="scroll-margin-top: 100px;">
                    Found {{ contents.length}} {{ filter }} Datasets
                    </h3>
                  <p>
                    <b>Let’s get started!</b>
                  </p>

    <!-- Related content -->
    <div class="mt-8">
      <!--h3 class="h3 mb-8">Related</h3-->
      <span v-for="content in contents" :key="content.id">
      <a class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" 
      :href="content.url">
        <div>
          <div class="text-normal font-medium mb-1">{{ content.title }}</div>
          <div class="text-sm text-gray-600">{{ content.body }}</div>
        </div>
        <svg class="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </a>
      </span>
    </div>
                  
                </div>

      <hr class="w-full h-px pt-px bg-gray-200 border-0 mt-8" />


              </div>

            </div>

          </div>
        </div>
      </section>

    
      
      <CtaAlternative />      

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import CtaAlternative from './../partials/CtaAlternative.vue'
import Footer from './../partials/Footer.vue'
import NavDatasets from './../partials/NavDatasets.vue'
import json_datasets from './../assets/json/datasets_20201223.json'
// import CtaHub from './../partials/CtaHub.vue'

export default {
  name: 'Datasets',
		data () {
			return {
            ready: false,
            contents: [],
            filter: '',
            item: '',
            v: '',
            sidebarNavOpen: false,
            sidebarLinkOpen: true,
            task_description: {
              'Classification': 'Text Classification is the process of assigning the text to classes or categories based on predefined features such as words or sentences.',
              'Text classification': 'Text Classification is the process of assigning the text to classes or categories based on predefined features such as words or sentences.',
              'Summarization': 'Summarization is a natural language processing (NLP) task that requires, given a document of arbitrary length, a summarizer to return a shorter, relevant subset of the input for a specific purpose.',
              'Translation': 'Language translation, or machine translation, is the use of computer software to translate text from a document in one language to text in another language.',
              'Ner': 'Named Entity Recognition (NER), is the process of converting unstructured text (text without the use of a markup language) into an annotated ontology leveraging a deep understanding of a specific domain (e.g., Medicine, Finance, etc) and language (e.g., English, Chinese, etc).',
              'Corpora': 'NLP corpora datasets is a type of structured learning data that contains texts in various domains.',
              'Sentiment analysis': 'Sentiment analysis is the process of understanding how the writer or speaker’s sentiment affects the tone of a given piece of text.',
              'Sentiment': 'Sentiment analysis is the process of understanding how the writer or speaker’s sentiment affects the tone of a given piece of text.',
              'Speech': 'Speech recognition is a natural language processing (NLP) task that listens to and comprehends human speech. Speech Recognition interprets the spoken words of a natural language such as English as computer commands or data input.',
              'Embeddings': 'Word embeddings is a set of techniques in natural language processing that is typically used for building contextually-sensitive word representation vectors.',
              'Generation': 'This is a sub-domain of Natural Language Processing (NLP). Text Generation converts natural language to natural language, such that the converted text is indistinguishable from that which was originally generated by a human writer. ',
            
            }
            }
        },
        components: {
            Header,
            CtaAlternative,
            Footer,
            NavDatasets
        },
        beforeRouteLeave (to, from, next) {
            if (to.meta.componentName === 'Page') {
            this.loadContents(to.path, next)
            } else {
            next()
            }
        },
        beforeRouteUpdate (to, from, next) {
            this.loadContents(to.path, next)
        },
        created(){
            this.loadContents(this.$route.path)
        },
        mounted(){
            this.loadContents(this.$route.path)
        },
        methods: {
            loadContents (path, callback) {
                
                
                
                var p_str = path.split('/')[2]//.split("-").join(" ")
                
                // console.log(path.split('/')[-1])
                // console.log(p, p_str)

                var data = json_datasets

                var value = p_str.split('-')
                value.pop()
                var col = p_str.split('-').slice(-1)[0]
                
                // this.content = response.data
                this.filterBy(data, col, value.join(' ').replace('(','').replace(')','').replace(':',''))

                this.v = col
                // console.log(data[0])
                // console.log(data[data.length-1])


                //if (Array.isArray(data.body)) {
                //  this.content.body = data.body.join(" ")
                //}

                // var total = data.length
                

                String.prototype.capitalize = function() {
                    return this.charAt(0).toUpperCase() + this.slice(1);
                }

                this.filter = String(value.join(' ').replace('(','').replace(')','').replace(':','')).capitalize()
                var count = this.contents.length
                if (count && count > 1) {
                  count = '+'+count+' '
                } else {
                  count = ''
                }
                

                document.title = count + this.filter +' Datasets - NLP Database'

                this.ready = true

                if (typeof callback === 'function') {
                    callback()
                }

                
            },
            filterBy(data, col, value){

                if (value == 'programming languages') {
                  value = "python"
                } else if ( value == 'sentiment analysis') {
                  value = "sentiment"
                } else if ( value == 'multi lingual') {
                  value = "multi"
                } else if ( value == 'text classification') {
                  value = "classification"
                }

                // iterate over each element in the array
                for (var i = 0; i < (data.length-1); i++){
                    // look for the entry with a matching `code` value
                    if ( (String(data[i][col].replace(/\s+/g, '-').toLowerCase()).includes(String(value.toLowerCase()))) ){
                    // we found it
                    // obj[i].name is the matched result
                    // console.log(data[i])
                    
                    this.contents.push({
                        id: Math.floor(Math.random() * 111111111111111),
                        title: data[i].dataset,
                        tag: data[i],
                        body: data[i].description,
                        pdf: "https://docs.google.com/gview?url=" + data[i].paper +"&embedded=true",
                        url: "/datasets/"+data[i].dataset.replace(/\s+/g, '-').toLowerCase()
                    })

                    // this.content = data[i]
                    }
                }
                
            }
        }
	}
</script>
