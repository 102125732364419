<template>
    <section class="relative">
  
      <!-- Section background (needs .relative class on parent and next sibling elements) -->
      <div class="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-gray-100 pointer-events-none" aria-hidden="true"></div>
      <div class="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2"></div>
  
      <div class="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div class="py-12 md:py-20">
  
          <!-- Section header -->
          <div class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 class="h2 mb-4">
                <!-- Explore tailor-made AI <span data-entity="gpe">solutions</span> -->
                Empower your team to automate business process with custom AI
            </h2>
            <p class="text-xl text-gray-600">
                Custom task-specific LLMs within a no-code platform that allows you to focus on the busines problem, 
                making it easier to automate and scale business process.
            </p>
          </div>
  
          <!-- Items -->
          <div class="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">
  
            <!-- 1st item -->
            <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                <h2 class="text-5xl font-bold leading-snug tracking-tight mb-1">
                    🏷
              </h2>
              <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">
                Customer Support
              </h4>
              <hr>
              <p class="text-gray-600 text-center">
                Quickly categorize and respond to customer tickets. Leaving more time for complex issues.
                </p>
            </div>
  


            <!-- 5th item -->
            <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                <h2 class="text-5xl font-bold leading-snug tracking-tight mb-1">
                    💬
              </h2>
              <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">
                Customer Insights
              </h4>
              <p class="text-gray-600 text-center">
                Automatically categorize reviews by topic, issue, sentiment, and engagement.
                </p>
            </div>
  
            <!-- 3rd item -->
            <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                <h2 class="text-5xl font-bold leading-snug tracking-tight mb-1">
                    🗣️
              </h2>
              <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">
                Content Moderation
              </h4>
              <p class="text-gray-600 text-center">
                Automatically detect and remove inappropriate, offensive, or harmful content.
              </p>
            </div>
  
            <!-- 4th item -->
            <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                <h2 class="text-5xl font-bold leading-snug tracking-tight mb-1">
                    👩‍⚕️
              </h2>
              <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">
                Healthcare
              </h4>
              <p class="text-gray-600 text-center">
                Extract critical data from medical records, lab reports, and patient feedback.
            </p>
            </div>
  

            <!-- 2nd item -->
            <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              <h2 class="text-5xl font-bold leading-snug tracking-tight mb-1">
                📊
              </h2>
              <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">
                Finance
              </h4>
              <p class="text-gray-600 text-center">
                Analyze market sentiment, reports, and customer feedback. Stay ahead in investment decisions.
              </p>
            </div>
  
            <!-- 6th item -->
            <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                <h2 class="text-5xl font-bold leading-snug tracking-tight mb-1">
                    📄
              </h2>
              <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">Human Resources (HR)</h4>
              <p class="text-gray-600 text-center">
                Identify the best-fit candidates by matching skills, experience, and qualifications to job requirements.
             </p>
            </div>
  
          </div>
  
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'FeaturesBlocks',
  }
  </script>