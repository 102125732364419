<template>
    
  <header class="fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out" :class="{ 'bg-white blur shadow-lg': !top }">
    
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="flex items-center justify-between h-16 md:h-20">
        <router-link to="/" class="block" aria-label="Metatext">
            
            

            
            
        
        <div class="max-w-screen-xl flex flex-wrap items-center text-blue-600 justify-between mx-auto p-1 dark:text-white">
    <a href="" class="flex items-center">
        <svg style="margin-right: 0.5em;" xmlns="http://www.w3.org/2000/svg" 
                    class="iconx icon-tabler icon-tabler-cube-unfolded" 
                    width="42" height="42" 
                    viewBox="0 0 24 24" 
                    stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M2 15h10v5h5v-5h5v-5h-10v-5h-5v5h-5z"></path>
   <path d="M7 15v-5h5v5h5v-5"></path>
</svg>
        <span class="self-center text-3xl font-semibold whitespace-nowrap dark:text-white">
            Metatext
        </span>
    </a>
    </div>
</router-link>
        <!-- Site branding -->
        <!-- <div class="flex-shrink-0 mr-4">
          
          
        </div> -->
  
        <!-- Desktop navigation -->
        <nav class="hidden md:flex md:flex-grow">

          <!-- Desktop menu links -->
          <ul class="flex flex-grow justify-end flex-wrap items-center">
                    
            <!-- 1st level: hover -->
            <!-- <Dropdown title="Use cases">
              <li style="min-width: 300px;">
                <a href="/page/use-cases/response-analysis" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Response Analysis</a>
                <a href="/page/use-cases/fraud-detection" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Fraud Detection</a>
                <a href="/page/use-cases/ticket-routing" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Ticket Routing</a>
                <a href="/page/use-cases/compliance-document-analysis" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Document Analysis</a>
                <a href="/page/use-cases/healthcare-patient-triage" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Healthcare Triage</a>
              </li>
            </Dropdown> -->

            
            <!-- <Dropdown title="Product">
              <li style="min-width: 300px;">
                <a href="/#use" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">How it works</a>
                <a href="/#use" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Text classification</a>
                <a href="/#use" class="font-medium text-sm text-gray-500 hover:text-gray-500 flex py-2 px-5 leading-tight">Text extraction (Coming soon)</a>
                <a href="/#use" class="font-medium text-sm text-gray-500 hover:text-gray-500 flex py-2 px-5 leading-tight">Text summarization (Coming soon)</a>
                <a href="/#use" class="font-medium text-sm text-gray-500 hover:text-gray-500 flex py-2 px-5 leading-tight">Text generation (Coming soon)</a>
                <a href="/#use" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Integrations</a>
              </li>
            </Dropdown> -->
            <!-- <Dropdown title="Use cases">
                <li style="min-width: 300px;">
                <a href="/use-cases/customer-support-intent-detection" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Customer support intent</a>
                <a href="/use-cases/classify-email-responses" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Classify e-mail response</a>
                <a href="/use-cases/classify-content-moderation"  class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Content moderation</a>
                <a href="/use-cases/classify-financial-contracts" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Classify contracts</a>
              </li>
            </Dropdown> -->
            <Dropdown title="Product">
              <li>
                <a href="/product" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">How it works</a>
                <a href="/documentation/intro#nlp" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">AI and NLP concepts</a>
                <a href="/documentation/intro#llm" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Large Language Models</a>
                <a href="https://docs.metatext.ai" target="_blank" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Quickstart docs</a>
              </li>
            </Dropdown>

            <!-- <li>
              <a href="/product" 
              class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
              How It Works?
              </a>
            </li> -->

            <li>
              <router-link to="/pricing" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
              Pricing
              </router-link>
            </li>  

            <li>
              <router-link to="/about" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
              About us
              </router-link>
            </li> 

            <!-- <Dropdown title="Resources">
              <li>
                <a href="/blog" class="font-medium text-sm text-gray-800 hover:text-gray-900 flex py-2 px-5 leading-tight">Blog</a>
                <a href="/pages" class="font-medium text-sm text-gray-800 hover:text-gray-900 flex py-2 px-5 leading-tight">Page links</a>
                
                <a href="/page/no-code-ai-what-is" class="font-medium text-sm text-gray-800 hover:text-gray-900 flex py-2 px-5 leading-tight">No-code AI tools</a>
                
                <a href="/documentation" class="font-medium text-sm text-gray-800 hover:text-gray-900 flex py-2 px-5 leading-tight">Documentation</a>
                <a href="https://app.metatext.ai/explore" target="_blank" class="font-medium text-sm text-gray-800 hover:text-gray-900 flex py-2 px-5 leading-tight">🔥 Explore Pre-build Models</a>
                <a href="https://discord.gg/NdWMd8mrS5" target="_blank" class="font-medium text-sm text-gray-800 hover:text-gray-900 flex py-2 px-5 leading-tight">💬&nbsp;Join our community</a>
                <a href="/nlp/datasets/english" class="font-medium text-sm text-gray-800 hover:text-gray-900 flex py-2 px-5 leading-tight">NLP Hub</a>
                <a href="/datasets" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Datasets</a>
                <a href="/models" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">Models</a>
                <a href="/apis" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">APIs</a>
                
              </li>
            </Dropdown> -->


            
            <!--li>
              <router-link to="/blog" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Blog</router-link>
            </li-->

             <!--li>
              <a href="https://fvd1htho55.execute-api.eu-west-1.amazonaws.com/v1/doc" target="_blank" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">API</a>
            </li-->
            
            <!--li>
              <router-link to="/datasets" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">NLP Datasets</router-link>
            </li-->  

            <!--li>
              <router-link to="/models" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
              Models</router-link>
            </li-->  

            <!--li>
              <router-link to="/page/open-ai-gpt-3-portuguese" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
              GPT-3 | Open AI Partner
                
              </router-link>
            </li--> 

            <!--li>
              <router-link to="/blog" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Blog</router-link>
            </li-->

            <!--li>
                  <a href="https://e7uqx26jzwq.typeform.com/to/vCE0W6hR" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Contact Us</a>
            </li-->   

          </ul>

          <!-- Desktop sign in links -->
          <ul class="flex flex-grow justify-end flex-wrap items-center">
            <li>
              <a href="/signin" class="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">
              Login
              </a>
            </li>
            <li>
              <a href="/book-intro-call" class="btn-sm text-white bg-blue-600 hover:bg-blue-700 ml-3">
                <span>
                  Book a demo
                </span>
                <svg class="w-3 h-3 fill-current text-blue-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill-rule="nonzero" />
                </svg>
              </a>
            </li>
          </ul>

        </nav>

        <!-- Mobile menu -->
        <div class="flex md:hidden">

          <!-- Hamburger button -->
          <button class="hamburger" ref="hamburger" :class="{ active: mobileNavOpen }" aria-controls="mobile-nav" :aria-expanded="mobileNavOpen" @click="mobileNavOpen = !mobileNavOpen">
            <span class="sr-only">Menu</span>
            <svg class="w-6 h-6 fill-current text-gray-900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <rect y="4" width="24" height="2" />
              <rect y="11" width="24" height="2" />
              <rect y="18" width="24" height="2" />
            </svg>
          </button>

          <!-- Mobile navigation -->
          <transition
            enter-active-class="transition ease-out duration-200 transform"
            enter-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >          
            <nav
              id="mobile-nav"
              ref="mobileNav"
              v-show="mobileNavOpen"
              class="absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll bg-white transition-all duration-300 ease-in-out"
            >
              <ul class="px-5 py-2">
                
                <li>
                  <a href="/#train" 
                  class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                  Auto Training
                  </a>
                </li>

                <li>
                  <a href="/#deploy" 
                  class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                  Fast Deployment
                  </a>
                </li>

                <li>
                  <a href="/#monitor" 
                  class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                  Monitoring
                  </a>
                </li>

                <li>
              <a href="/pricing" 
              class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
              Pricing
              </a>
            </li>

            <li>
              <a href="/about" 
              class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
              About Us
              </a>
            </li>
                
                <!--li>
                  <router-link to="/blog" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Blog</router-link>
                </li-->

                <li>
                  <a href="https://fvd1htho55.execute-api.eu-west-1.amazonaws.com/v1/doc" target="_blank" class="flex text-gray-600 hover:text-gray-900 py-2">API</a>
                </li>
                <Dropdown title="Use cases">
                    <li style="min-width: 300px;">
                        <a href="/page/use-cases/response-analysis" class="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"></a>
                    </li>
                </Dropdown>
                
                <li>
                  <router-link to="/datasets" class="flex text-gray-600 hover:text-gray-900 py-2">NLP Datasets</router-link>
                </li>   
                <!--li>
                  <a href="https://e7uqx26jzwq.typeform.com/to/vCE0W6hR" class="flex text-gray-600 hover:text-gray-900 py-2">Contact Us</a>
                </li-->   
                <li>
                  <!--router-link to="/signup" class="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3"-->
                  <a href="/book-intro-call" target="_blank" class="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 w-full my-2">
                    <span>Book a demo</span>
                    <svg class="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill-rule="nonzero" />
                    </svg>
                  <!--/router-link-->
                  </a>
                </li>
                <li>
                  <a href="/signin" class="flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center">Login</a>
                </li>
                <!--li>
                  <router-link to="/signup" class="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 w-full my-2">
                    <span>Sign up</span>
                    <svg class="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill="#999" fill-rule="nonzero" />
                    </svg>                
                  </router-link>
                </li-->
              </ul>
            </nav>
          </transition>

        </div>

      </div>
    </div>
  </header>
</template>

<script>
import Dropdown from './../utils/Dropdown.vue'

export default {
  name: 'Header',
  components: {
    Dropdown
  },
  data: function () {
    return {
      mobileNavOpen: false,
      top: true
    }
  },
  methods: {
    clickOutside(e) {
      if (!this.mobileNavOpen || this.$refs.mobileNav.contains(e.target) || this.$refs.hamburger.contains(e.target)) return
      this.mobileNavOpen = false
    },
    keyPress() {
      if (!this.mobileNavOpen || event.keyCode !== 27) return
      this.mobileNavOpen = false
    },
    handleScroll() {
      this.top = window.pageYOffset > 10 ? false : true
    }
  },  
  mounted() {
    document.addEventListener('click', this.clickOutside)    
    document.addEventListener('keydown', this.keyPress)
    document.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOutside)
    document.removeEventListener('keydown', this.keyPress)
    document.removeEventListener('scroll', this.handleScroll)
  }
}
</script>