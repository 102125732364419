<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <section>
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <div class="pt-32 pb-12 md:pt-40 md:pb-20">

            <!-- Main content -->
            <div class="md:flex md:justify-between" data-sticky-container>

              <!-- Sidebar -->
              <aside class="relative my-12 md:my-0 md:w-64 md:mr-12 lg:mr-20 md:flex-shrink-0">
                <div data-sticky data-margin-top="100" data-sticky-for="768" data-sticky-wrap>

                  <!-- Search form -->
                  <div class="mb-4 pb-4 border-b border-gray-200">
                    <div class="flex flex-wrap">
                      <div class="w-full">
                        <a class="flex items-center hover:underline" href="/apis">
                          <div class="flex items-center flex-grow">
                            
                            <span>Go back to search apis</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <button class="flex items-center justify-between text-lg font-medium text-gray-900 w-full my-4 md:hidden">
                    <span>APIs navigation</span>
                    <svg class="w-3 h-3 fill-current text-blue-600 flex-shrink-0 ml-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <rect y="7" width="16" height="2" rx="1" class="transform origin-center transition duration-200 ease-out"/>
                      <rect y="7" width="16" height="2" rx="1" class="transform origin-center rotate-90 transition duration-200 ease-out" />
                    </svg>
                  </button>

                  <NavAPIs col="Category" />

                </div>
              </aside>

              <!-- Page container -->
              
          <div class="md:flex-grow">

                <div class="text-lg text-gray-600">
                  <h2 class="h2 text-gray-900 mb-4">
                    {{ content.tag.apiname }} API
                  </h2>
                  

                  <h3 v-if="content.tag.Category" id="quick" class="h3 text-gray-900 mb-8" style="scroll-margin-top: 100px;">{{ content.tag.Category }} task API</h3>

                  <p class="mb-8">
                      The {{ content.tag.apiname}} API service works with {{ content.tag.Category }} Natural Language Processing task.
                    {{ content.tag.intro }}
                  </p>
                  <h3 id="installation" class="h3 text-gray-900 mb-4" style="scroll-margin-top: 100px;">
                      Specifications for version {{ content.tag.Versions}}
                  </h3>
                  <p class="mb-8">
                    <span v-for="labels in content.tag.specs.split('<br>')" :key="labels">
                        
                        <p class="text-normal" v-html="labels" style="label: bold; a: bold">
                        </p>

                    </span>
                  </p>
                  <!--p class="mb-8">
                    
                    {{
                        content.tag.labels
                    }}
                  </p-->
                </div>

                <!-- Related content -->
                <!--div class="mt-8">
                  <h3 class="h3 mb-8">Related</h3>
                  <a class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="#0">
                    <div>
                      <div class="text-normal font-medium mb-1">Folder components</div>
                      <div class="text-sm text-gray-600">Learn more about orci ac auctor augue mauris augue neque gravida.</div>
                    </div>
                    <svg class="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                    </svg>
                  </a>
                  <a class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="#0">
                    <div>
                      <div class="text-normal font-medium mb-1">Deploy hooks</div>
                      <div class="text-sm text-gray-600">Learn more about risus nullam eget felis eget nunc.</div>
                    </div>
                    <svg class="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                    </svg>
                  </a>
                  <hr class="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
                </div-->

                <!-- Feedback -->
                <div class="pt-6">
                  <div class="flex flex-col text-center sm:text-left sm:flex-row sm:justify-between sm:items-center">
                    <div class="font-medium mb-4 md:mb-0">Was this page helpful? <br>Share to help more people.</div>

                    
                    <twitter-button
                      class="share-button--outline" 
                      
                    />
                    <facebook-button
                      class="share-button--outline" 
                      
                    />
                    <whatsApp-button
                      class="share-button--outline" 
                      
                    />
                    <telegram-button
                      class="share-button--outline" 
                      
                    />
                    <linkedIn-button
                      class="share-button--outline" 
                      
                    />
                  </div>
                  <hr class="w-full h-px pt-px bg-gray-200 border-0 mt-6" />
                </div>

              </div>

            </div>

          </div>
        </div>
      </section>

    
      
      <CtaAlternative />      

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>
<script>
import Header from './../partials/Header.vue'
import CtaAlternative from './../partials/CtaAlternative.vue'
import Footer from './../partials/Footer.vue'
import json_datasets from './../assets/json/apis_5.json'
import TwitterButton from "vue-share-buttons/src/components/TwitterButton";
import FacebookButton from "vue-share-buttons/src/components/FacebookButton";
import WhatsAppButton from "vue-share-buttons/src/components/WhatsAppButton";
import TelegramButton from "vue-share-buttons/src/components/TelegramButton";
import LinkedInButton from "vue-share-buttons/src/components/LinkedInButton";
//import RedditButton from "vue-share-buttons/src/components/RedditButton";
import NavAPIs from './../partials/NavAPIs.vue'

export default {
  name: 'APIs',
		data () {
			return {
            ready: false,
            content: {}
            }
        },
        components: {
            Header,
            CtaAlternative,
            Footer,
            TwitterButton,
            FacebookButton,
            WhatsAppButton,
            TelegramButton,
            LinkedInButton,
            NavAPIs
        },
        beforeRouteLeave (to, from, next) {
            if (to.meta.componentName === 'Page') {
            this.loadContents(to.path, next)
            } else {
            next()
            }
        },
        beforeRouteUpdate (to, from, next) {
            this.loadContents(to.path, next)
        },
        created(){
            this.loadContents(this.$route.path)
        },
        methods: {
            loadContents (path, callback) {
                

                // var p = path.split('/')[2]
                var p_str = path.split('/')[2]//.split("-").join(" ")

                // console.log(path.split('/')[-1])
                // console.log(p, p_str)

                var data = json_datasets
                
                // this.content = response.data
                this.filterName(data, p_str)
                // console.log(data[0])
                // console.log(data[data.length-1])


                //if (Array.isArray(data.body)) {
                //  this.content.body = data.body.join(" ")
                //}

                this.ready = true

                if (typeof callback === 'function') {
                    callback()
                }
            },
            filterName(data, p_str){

                // iterate over each element in the array
                for (var i = 0; i < (data.length-1); i++){
                    // look for the entry with a matching `code` value
                    if ( (String(data[i].apiname.replace(/\s+/g, '-').toLowerCase()) == String(p_str.toLowerCase())) ){
                    // we found it
                    // obj[i].name is the matched result
                    // console.log(data[i])
                    
                    this.content = {
                        title: data[i].apiname,
                        tag: data[i],
                        task: data[i].Category,
                        body: data[i].intro,
                        url: "/redirect/" , //+ data[i].dataset.replace(/\s+/g, '-').toLowerCase(),
                        //pdf: "https://docs.google.com/gview?url=" + data[i].paper +"&embedded=true",
                    }

                    document.title = data[i].apiname + ' - NLP Hub - Metatext'

                    break;

                    // this.content = data[i]
                    }
                }
            }
        }
	}
</script>
<style scoped>

</style>
