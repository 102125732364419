import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'

Vue.config.productionTip = false

import Vue from 'vue'
import App from './App.vue'
import router from './router'

import store from './store'

import FirebasePlugin from './plugins/FirebasePlugin'

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import VueI18n from 'vue-i18n'
import VueHead from 'vue-head'

Vue.use(VueHead)
Vue.use(VueI18n)
// import Buefy from 'buefy'
// import 'buefy/dist/buefy.css'
// Vue.use(Buefy)

import 'vue-good-table/dist/vue-good-table.css'

import '@/css/style.scss';

Sentry.init({
  dsn: 'https://9418e607dada4adba467367c22ee95e9@sentry.io/1771484',
  integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })],
  /*
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      var name = "";
      var email = ""

      if (!hint) {
        hint = ""
      }

      if (firebase.auth().currentUser) {
        name = firebase.auth().currentUser.displayName
        email = firebase.auth().currentUser.email
      }
      Sentry.showReportDialog({
        eventId: event.event_id, user: {
          name, email
        }
      });
    }
    return event;
  }
  */
});

firebase.initializeApp({
  apiKey: "AIzaSyB6sPH9XEoXxZwfMfGE-fJOVKSljKPsEGk",
  authDomain: "ml-saas.firebaseapp.com",
  databaseURL: "https://ml-saas.firebaseio.com",
  projectId: "ml-saas",
  storageBucket: "ml-saas.appspot.com",
  messagingSenderId: "915060039042",
  appId: "1:915060039042:web:130852640a0f9c737a439b",
  measurementId: "G-C0MC8SNSLK"
})

firebase.analytics()


const unsubscribe = firebase.auth().onAuthStateChanged((auth) => {
  unsubscribe()

  if (auth) {
    Sentry.configureScope(scope => {
      scope.setUser({
        email: auth.email,
        username: auth.displayName,
        id: auth.uid
      })
    })
  }

  Vue.use(FirebasePlugin)

// import en from './locales/en'
// import ja from './locales/pt'

// const messages = {
// en,
// ja
// }


const messages = {
    en: {
        title: 'Metatext - AI Safety Platform',
        hero: {
            headline1: 'Fine-tune SLMs',
            headline2: 'better, cheaper and easier',
            description: 'Metatext helps tech teams to automate workflows by classifying, tagging and generating texts with task-specific language models on your data and domain.',
            signup: 'Get started',
            book: 'Book a demo'
        },
        features: {
            headline1: 'What\'s possible?',
            description1: 'General purpose LLMs doesn\'t works well enough for domain-specific problems. Metatext enables you to fine-tune models in an easy way. Whether you need to classify complex customer emails, extract key terms on legal contracts or generate a format specific summary on NASDAQ\'s reports.',
            feature1: 'Text classification',
            feature2: 'Text tagging',
            feature3: 'Text generation',
        }
    },
    pt: {
        title: 'Metatext - AI Safety Platform',
        hero: {
            headline1: 'Categorize e extraia texto',
            headline2: '10x melhor e mais fácil',
            description: 'Metatext ajuda você a categorizar e extrair informações de textos e documentos com modelos de linguagem customizados com seus dados e expertise.',
            signup: 'Teste grátis',
            book: 'Fale conosco'
        },
        features: {
            headline1: 'O que é possível?',
            description1: 'LLM genéricas não funcionam bem o suficiente para problemas de domínios especificos (ex: jurídico, financeiro, etc). Metatext permite que você treine modelos de linguagem de forma fácil. Seja para classificar emails de clientes, extrair termos chaves de contratos ou gerar um sumário de relatórios financeiros.',
            feature1: 'Categorização',
            feature2: 'Extração de texto',
            feature3: 'Geração de texto',
        }
    }
}

  // Create VueI18n instance with options
const i18n = new VueI18n({
    //locale: navigator.language, // set locale
    fallbackLocale: 'en',
    messages, // set locale messages
})

  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})

