<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 pb-12 md:pt-40 md:pb-20">

        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-left pb-12 md:pb-16">
          <h2 class="h2 mb-4">
                    We are passionate engineers, scientists and entrepreneurs
                </h2>
                <p class="text-xl text-gray-600">
                    We believe that Artificial Intelligence (AI) is the most impactful technology of this decade.
                    Metatext connects with our vision to democratize the access to AI for everyone, without the deep expertise in code, math, and statistics. But following the best practices and guidance from expert professionals.
                    <br><br>
                    Metatext is a machine learning as a service platform, that are build by experts to guide developers, analysts, product managers, and other roles, with the best practices to solve real world problems.
                    <br><br>
                    In our team we have researchers with vast experience in computer science, linguistics, AI, and specially <a href="#">natural language processing</a>, 
                    as we have passage at big tech companies and startups, with backgrounds from different kind of AI end-to-end projects.
                    <br><br>
                    Metatext aims to made AI accessible to everyone.
                </p>
                
                <!--figure>
                    <c-image
                        class="image-larger"
                        :src="require('@/assets/images/image-placeholder.png')"
                        alt="Image placeholder"
                        :width="712"
                        :height="400" />
                    <figcaption class="text-color-low">A super-nice image <span role="img" aria-label="smile">😀</span></figcaption>
                </figure-->
                <h4 class="h4 mb-4 mt-20">
                    Open positions!
                </h4>
                <p class="text-xl text-gray-600">
                    As our mission becomes reality, we are openning positions to growth fast and efficiently. 
                </p>

                <div class="mt-6 mb-6">

                  <a class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-gray-100 shadow-md hover:bg-white hover:shadow-lg mb-3" href="mailto:hi@metatext.io">
                    <div class="font-medium">Sales Development Representative (SDR)</div>
                    <svg class="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.707 1h-8v2h5.586L1 13.293l1.414 1.414L12.707 4.414V10h2V2a1 1 0 00-1-1z" />
                    </svg>
                  </a>

                  <a class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-gray-100 shadow-md hover:bg-white hover:shadow-lg mb-3" href="mailto:hi@metatext.io">
                    <div class="font-medium">Content Writer</div>
                    <svg class="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.707 1h-8v2h5.586L1 13.293l1.414 1.414L12.707 4.414V10h2V2a1 1 0 00-1-1z" />
                    </svg>
                  </a>
                  
                  <!-- 1st Item -->
                  <a class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-gray-100 shadow-md hover:bg-white hover:shadow-lg mb-3" href="mailto:hi@metatext.io">
                    <div class="font-medium">Frontend Software Engineer (VueJs)</div>
                    <svg class="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.707 1h-8v2h5.586L1 13.293l1.414 1.414L12.707 4.414V10h2V2a1 1 0 00-1-1z" />
                    </svg>
                  </a>

                  <a class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-gray-100 shadow-md hover:bg-white hover:shadow-lg mb-3" href="mailto:hi@metatext.io">
                    <div class="font-medium">Backend Software Engineer (AWS, Python, APIs)</div>
                    <svg class="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.707 1h-8v2h5.586L1 13.293l1.414 1.414L12.707 4.414V10h2V2a1 1 0 00-1-1z" />
                    </svg>
                  </a>

                  

                  
                </div>
                <p class="text-xl text-gray-600">
                    We are a small team, and geo distributed. If you liked the product and think one of this positions is right for you, please reach us at hi@metatext.io we would love to meet you.
                </p>
            </div>

        

        <!--figure class="flex justify-center items-start">
          <img class="rounded shadow-2xl" :src="require('@/images/about-01.jpg')" width="768" height="432" alt="About us" />
        </figure-->

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutIntro',
}
</script>