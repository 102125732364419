import Vue from 'vue'
import Router from 'vue-router'

import HomeLandingPage from './pages/Home_Safety.vue'
import HomeFinetune from './pages/Home_Finetune.vue'
// import Tutorials from './pages/Tutorials.vue'
import Pricing from './pages/Pricing.vue'
// import Blog from './pages/Blog.vue'
// import BlogPost from './pages/BlogPost.vue'
// import Page from './pages/Page.vue'
// import PagePost from './pages/PagePost.vue'
import About from './pages/About.vue'
import Documentation from './pages/Documentation.vue'
import Support from './pages/Support.vue'
import PageNotFound from './pages/PageNotFound.vue'

import PretrainedModels from './pages/PretrainedModels.vue'
import PretrainedModelsSingle from './pages/PretrainedModelsSingle.vue'

import SignUp from './pages/SignUp.vue'
import SignIn from './pages/SignIn.vue'

import Products from './pages/Products.vue'

import Datasets from './pages/Datasets.vue'
import DatasetsPage from './pages/DatasetsPage.vue'
import DatasetsList from './pages/DatasetsList.vue'
import Redirect from './pages/Redirect.vue'

import APIs from './pages/APIs.vue'
import APIsPage from './pages/APIsPage.vue'
import APIsList from './pages/APIsList.vue'

import Models from './pages/Models.vue'
import ModelsPage from './pages/ModelsPage.vue'
import ModelsList from './pages/ModelsList.vue'

import HubList from './pages/HubList.vue'
import MarkdownList from './pages/MarkdownList.vue'
import firebase from 'firebase/app'

Vue.use(Router)

import BlogEntries from './statics/contents.json';

const blogRoutes = Object.keys(BlogEntries).map(section => {
  const children = BlogEntries[section].map(child => ({
    path: child.id,
    name: child.id,
    meta: {
        title: child.title + " - Metatext AI",
        description: child.description
    },
    component: () => import(`./statics/markdowns/${section}/${child.id}.md`)
  }))
  return {
    path: `/${section}`,
    name: section,
    props: true,
    component: () => import('./pages/MarkdownContents.vue'),
    children
  }
})

// const sessionRoutes = Object.keys(BlogEntries).map(section => {
//     return {
//       path: `/${section}`,
//       name: section,
//       props: true,
//       component: () => import('./pages/MarkdownList.vue')
//     }
//   })

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: HomeLandingPage,
      meta: {
        title: "Metatext - Mitigate the risks of adopting generative AI",
        // authPage: true,
        description: 'Metatext empowers enterprises to proactively identify and mitigate generative AI vulnerabilities, providing real-time protection against potential attacks that could damage brand reputation and lead to financial losses.'
      }
    },
    {
        path: '/safety-ai',
        component: HomeLandingPage,
        meta: {
            title: "Metatext - AI safety platform",
          // authPage: true,
          description: 'Metatext empowers enterprises to proactively identify and mitigate generative AI vulnerabilities, providing real-time protection against potential attacks that could damage brand reputation and lead to financial losses.'
        }
      },
      {
        path: '/safety',
        component: HomeLandingPage,
        meta: {
            title: "Metatext - AI safety platform",
          // authPage: true,
          description: 'Metatext empowers enterprises to proactively identify and mitigate generative AI vulnerabilities, providing real-time protection against potential attacks that could damage brand reputation and lead to financial losses.'
        }
      },
      {
        path: '/security',
        component: HomeLandingPage,
        meta: {
            title: "Metatext - AI safety platform",
          // authPage: true,
          description: 'Metatext empowers enterprises to proactively identify and mitigate generative AI vulnerabilities, providing real-time protection against potential attacks that could damage brand reputation and lead to financial losses.'
        }
      },
      {
        path: '/docs',
        beforeEnter() {location.href = 'https://docs.metatext.ai'}
        
      },
      {
        path: '/book-demo',
        beforeEnter() {location.href = 'https://cal.com/rafaelsandroni/metatext-demo'}
        
      },
    {
        path: '/finetune',
        component: HomeFinetune,
        meta: {
          title: "Metatext",
          // authPage: true,
          description: 'Metatext simplifies the process of creating customized AI/NLP models, even without expertise in ML, Data Science, or MLOps. With just a few steps, automate complex workflows, and relying on intuitive UI and APIs to handle the heavy work.'
        }
      },
    {
        path: '/text-classification',
        component: HomeFinetune,
        meta: {
          title: "Text Classification - Annotate, Build & Maintain Robust NLP Products",
          // authPage: true,
          description: 'Metatext simplifies the process of creating customized text classification models, even without expertise in ML, Data Science, or MLOps. With just a few steps, automate complex workflows, and relying on intuitive UI and APIs to handle the heavy work.'
        }
    },
    {
      path: '/text-extraction',
      component: HomeFinetune,
      meta: {
        // authPage: true,
        // description: 'Metatext is an end-to-end AI solution to extract valuable information from text data. Create and deploy custom machine learning models and say good bye to expensive and manual tasks.'
      }
    },
    {
        path: '/text-generation',
        component: HomeFinetune,
        meta: {
          // authPage: true,
          // description: 'Metatext is an end-to-end AI solution to extract valuable information from text data. Create and deploy custom machine learning models and say good bye to expensive and manual tasks.'
        }
      },
    // {
    //   path: '/tutorials',
    //   component: Tutorials
    // },
    {
      path: '/api/:id',
      component: Products
    },
    {
      path: '/pricing',
      component: Pricing
    },
    // {
    //   path: '/blog',
    //   component: Blog,
    //   meta: {
    //     title: "Building AI with No-Code - Metatext",
    //     description: 'Stay up to date on the Latest Technical Papers, Use Cases, and News in Machine Learning and Business Applications.'
    //   }
    // },
    // {
    //   path: '/blog/:pageId/:id?',
    //   component: BlogPost,
    //   props: true
    // },
    // {
    //   path: '/doc/:pageId/:id?',
    //   component: BlogPost,
    //   props: true
    // },
    // {
    //   path: '/pages',
    //   component: Page
    // },
    // {
    //   path: '/page',
    //   component: Page
    // },
    // {
    //     path: '/p/:id/:subid',
    //     component: PagePost
    // },
    // {
    //   path: '/page/:id/:subid',
    //   component: PagePost,
    //   props: true
    // },
    {
        path: '/trained-models',
        component: PretrainedModels,
        meta: {
          title: 'Explore AI Models - Metatext',
          description: 'Models trained by Metatext Community. Covering tasks from classification to question answering, languages from English, Portuguese to Arabic.'
        }
      },
      {
        path: '/trained-models/:id',
        component: PretrainedModelsSingle
      },
    {
      path: '/about',
      component: About,
      meta: {
        title: "Metatext - AI Platform for Custom NLP Models",
      }
    },
    {
      path: '/documentation/:docId?',
      component: Documentation,
      props: true,
    },
    {
      path: '/product',
      component: Support
    },    
    {
      path: '/datasets/:page',
      component: DatasetsPage
    },   
    {
      path: '/datasets',
      component: Datasets,
      meta: {
        title: 'Curated NLP Database - Repository of 1000+ NLP Datasets',
        description: 'Find 1000+ NLP Datasets. Covering tasks from classification to question answering, languages from English, Portuguese to Arabic.'
      }
    },
    {
      path: '/datasets-list/:page',
      component: DatasetsList      
    },   
    // {
    //   path: '/nlp/:level1',
    //   component: HubList      
    // },  
    {
      path: '/nlp/:level1/:level2',
      component: HubList      
    },      
    {
      path: '/redirect/:page',
      component: Redirect
    },  
    {
      path: '/apis/:page',
      component: APIsPage,
    },   
    {
      path: '/apis',
      component: APIs,
      meta: {
        title: 'Best APIs for Natural Language Processing in 2021 - NLP Resource',
        description: 'Fing 200+ NLP APIs. Free, custom and proprietary APIs. Covering tasks from drug discovery to sentiment analysis, languages from Portuguese, English to Chinese.'
      }
    },   
    {
      path: '/apis-list/:page',
      component: APIsList      
    },  
      
    {
      path: '/models',
      component: Models,
      meta: {
        title: 'Models for Natural Language Processing - NLP Resource',
        description: '+6000 NLP models covering tasks from Summarization, Text Classification, to Text Generation. We hope you find this library useful in your development endeavors.'
      }
    },   
    {
      path: '/models/:page',
      component: ModelsPage,
      meta: {
        title: 'Models for Natural Language Processing - NLP Resource',
        description: '+6000 NLP models covering tasks from Summarization, Text Classification, to Text Generation. We hope you find this library useful in your development endeavors.'
      }
    }, 
    {
      path: '/models-list/:page',
      component: ModelsList      
    }, 
    {
      path: '/signup',
      beforeEnter() {location.href = 'https://app.metatext.ai/signup'}
      
    },
    {
        path: '/book-intro-call',
        beforeEnter() {location.href = 'https://cal.com/rafaelsandroni/metatext-demo'}
        
      },
    {
      path: '/signin',
      beforeEnter() {location.href = 'https://app.metatext.ai/login'}
    },
    {
        path: '/appsumo',
        beforeEnter() {location.href = 'https://app.metatext.ai/signup'}
      },
    // {
    //   path: '/signup',
    //   component: SignUp,
    //   meta: {
    //     title: 'Create a Free Account'
    //   }   
    // },
    
    {
      path: '/invite',
      component: SignUp,
      meta: {
        title: 'Invite'
      }     
    }, 
    {
      path: '/create-account',
      component: SignIn,
      meta: {
        title: 'Sign in'
      }    
    }, 
    {
      path: '*',
      component: PageNotFound
    },
    {
        path: '/tags',
        component: MarkdownList,
        props: true
    },
    {
        path: '/blog',
        component: MarkdownList,
        props: {session: "blog"}
    },
    {
        path: '/use-cases',
        component: MarkdownList,
        props: {session: "use-cases"}
    },
    {
        path: '/doc',
        component: MarkdownList,
        props: {session: "doc"}
    },
    {
        path: '/tag/:session?',
        component: MarkdownList,
        props: true
    },

    ...blogRoutes
  ]
})

// This callback runs before every route change, including on page load.

router.beforeEach((to, from, next) => {
  if (firebase.auth().currentUser && to.fullPath == "/") {
    next("/app")
  }
  else if (to.matched.some(it => it.meta.requiresAuth)) {
    if (firebase.auth().currentUser) {
      next()
    }
    else {
      next("/login")
    }
  }
  else if (to.matched.some(it => it.meta.authPage) && firebase.auth().currentUser) {
    next("/app")
  }
  else {
    next()
  }

  // const suffix_title = ""

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  if (document.title !== "Metatext") {
    // document.title = document.title + suffix_title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
})

export default router