<template>
  <div>
    
    <code-highlight language="python" class="python language-python">
    <pre>
# Import generic wrappers
from transformers import AutoModel, AutoTokenizer 


# Define the model repo
model_name = "{{ link.slice(1, link.length)  }}" 


# Download pytorch model
model = AutoModel.from_pretrained(model_name)
tokenizer = AutoTokenizer.from_pretrained(model_name)


# Transform input tokens 
inputs = tokenizer("Hello world!", return_tensors="pt")

# Model apply
outputs = model(**inputs)
    </pre>
    </code-highlight>
  </div>
</template>

<script>
import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";
import "vue-code-highlight/themes/prism-okaidia.css";

export default {
  name: "Code",
  data () {
			return {
                 props: ['link']
            }
  },
  components: {
    CodeHighlight
  },
  props: {
            link: String
        }
};
</script>