import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/app'
import { vuexfireMutations, firestoreAction } from 'vuexfire'

Vue.use(Vuex)

var CLOUD_FUNCTIONS_URL = process.env.VUE_APP_CLOUD_FUNCTIONS_URL
CLOUD_FUNCTIONS_URL = `https://us-central1-ml-saas.cloudfunctions.net`

export default new Vuex.Store({
  state: {
    modal: "",
    boards: "",
    pages: "",
    items: [],
    currentBoard: "",
    currentPage: "",
    relatedPages: "",
    tags: "",
    currentBoardItems: [],
    apps: null,
    models: ""
  },
  mutations: {
    setModal(state, modal) {
      state.modal = modal
    },
    ...vuexfireMutations
  },
  actions: {
    getUrlInfo(_, url) {
      return fetch(`${CLOUD_FUNCTIONS_URL}/backend/info?url=${url}`).then(response => {
        return response.json()
      })
    },
    getModels(state) {
        if (state.models) {
            return state.models
        }
        const url = 'https://metatext-global-content.s3.us-east-2.amazonaws.com/ai-tools/crawled-ai-apps.json'
        
        const response  = fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            return data
        })
        
        return response
    },
    getApps(state) {
        if (state.apps) {
            return state.apps
        }
        const url = 'https://metatext-global-content.s3.us-east-2.amazonaws.com/ai-tools/crawled-ai-apps.json'
        
        const response  = fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            return data
        })
        
        return response
    },
    getPages( state, payload) {
        // get pages from the backend
        // implement a fetch to the backend api

        let pageId = (payload?payload.pageId:null)
        
        if (pageId == null && state.pages) {
            return
        }
        
        // let url = `${process.env.VUE_APP_PIPELINE_URL}/v1/pipeline/blog/pages`
        let url = 'https://metatext-global-content.s3.us-east-2.amazonaws.com/blog/blog-list-pages.json'
        if (pageId != null) {
            url = `https://metatext-global-content.s3.us-east-2.amazonaws.com/blog/blog-page-${payload.pageId}.json`
        }
        
        const response  = fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            return data
        })
        if (pageId == null) {
            state.pages = response
        }
        
        return response
    },
    retrieveAllPages: firestoreAction(({ bindFirestoreRef, state }) => {
      if (state.pages) {
        return
      }
      
      return bindFirestoreRef('pages', firebase.firestore().collection(`pages`).where("author", "!=", "doc"))
    }),
    retrieveAllTags: firestoreAction(async ({ bindFirestoreRef }) => {
      await bindFirestoreRef('tags', 
            firebase.firestore().collection(`pages`).doc("tags")
          )
    }),
    retriveRelatedPages: firestoreAction(async ({ bindFirestoreRef }, tags) => {
      await bindFirestoreRef('relatedPages', firebase.firestore().collection(`pages`).where("tags", "array-contains-any", tags).limit(5))
    }),
    displayPageDetails: firestoreAction(async ({ bindFirestoreRef }, pageId) => {
      await bindFirestoreRef('currentPage', firebase.firestore().doc(`pages/${pageId}`))
      
    }),
    retrieveAllBoards: firestoreAction(({ bindFirestoreRef, state }) => {
      if (state.boards) {
        return
      }
      return bindFirestoreRef('boards', firebase.firestore().collection(`users/${firebase.auth().currentUser.uid}/boards`).orderBy('createdAt', 'desc'))
    }),
    displayBoardDetails: firestoreAction(async ({ bindFirestoreRef }, boardId) => {
      await bindFirestoreRef('currentBoard', firebase.firestore().doc(`users/${firebase.auth().currentUser.uid}/boards/${boardId}`))
      await bindFirestoreRef('currentBoardItems', firebase.firestore().collection(`users/${firebase.auth().currentUser.uid}/items`)
        .where('boardId', '==', boardId)
        .orderBy('createdAt', 'desc'))
    }),
    createBoard(_, board) {
      board.createdAt = firebase.firestore.FieldValue.serverTimestamp()
      firebase.firestore().collection(`users/${firebase.auth().currentUser.uid}/boards`).add(board)
    },
    updateBoard(_, { boardId, update }) {
      firebase.firestore().doc(`users/${firebase.auth().currentUser.uid}/boards/${boardId}`).update(update)
    },
    deleteBoard(_, boardId) {
      firebase.firestore().doc(`users/${firebase.auth().currentUser.uid}/boards/${boardId}`).delete()
    },
    createItem(_, item) {
      item.createdAt = firebase.firestore.FieldValue.serverTimestamp()
      firebase.firestore().collection(`users/${firebase.auth().currentUser.uid}/items`).add(item)
    },
    deleteItem(_, itemId) {
      firebase.firestore().doc(`users/${firebase.auth().currentUser.uid}/items/${itemId}`).delete()
    }
  }
})
