<template>
  


    <!-- Docs nav -->
    <nav class="md:block" :class="{ 'hidden' : !sidebarNavOpen }">
    <ul class="font-medium -my-2">
        
        

        <li class="py-2" v-if="filter == 'language'">
        <button class="flex items-center hover:underline" v-on:click="changeNav('task')">
            <div class="flex items-center flex-grow">
                
            <span>🔄 Switch menu to task</span>
            </div>
        </button>
        </li>

        <li class="py-2" v-if="filter == 'task'">
        <button class="flex items-center hover:underline" v-on:click="changeNav('language')">
            <div class="flex items-center flex-grow">
                
            <span>🔄 Switch menu to language</span>
            </div>
        </button>
        </li>
    
        <span>Datasets by {{ filter }}</span>

        


        <li class="py-2" v-for="l in data[filter]" :key="l">
        <a class="flex items-center hover:underline" :href="'/datasets-list/'+l.replace(/\s+/g, '-').replace('(','').replace(')','').replace(':','').toLowerCase()+'-'+filter">
            <div class="flex items-center flex-grow">
            <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 4H4c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1zM12 10H1c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1zM15 16H4c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1z" />
            </svg>
            <span>{{ l }} Datasets</span>
            </div>
        </a>
        </li>

    </ul>
    </nav>

</template>

<script>


export default {
  name: 'NavDatasets',
		data () {
			return {
                 props: ['col'],
                 filter: 'task',
            
            sidebarNavOpen: false,
            sidebarLinkOpen: true,
            data: {'language':
                ['Multi-Lingual', 'Portuguese', 'English', 'Chinese', 'Indian','Spanish','Persian', 'Indonesian', 'Tunisian', 'Arabic', 'Finnish', 
            'German', 'Russian', 'Afrikaans', 
            'Czech', 'Danish', 'Greek', 'Esperanto', 
            'French', 'Irish', 'Guarani', 'Hebrew', 'Hindi', 
            'Italian', 'Japanese', 
            'Korean', 'Latin',
            
            'Dutch', 'Norwegian',  'Polish', 
            
            'Swedish', 
            'Thai',
            'Turkish', 'Ukrainian', 'Urdu', 'Urdu Romanized', 'Uzbek', 'Vietnamese', 
            'Zulu', 
            'Programming Languages'
            ],
            'task':
            [
              'Classification',
              'Summarization',
              'Translation',
              'NER',
              'Corpora',
              'Sentiment Analysis',
              'Speech',
              'Embeddings',
              'Generation',
            ],
            }
        }
        },
        methods: {
            changeNav(col) {
                this.filter = col
            }
        },
        created() {
            this.filter = this.col
        },
        props: {
            col: String
        }
	}
</script>
