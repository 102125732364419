<template>

  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 pb-12 md:pt-40 md:pb-20">
        <div class="max-w-3xl mx-auto lg:max-w-none">

<article>

    <!-- Article header -->
    <header class="max-w-3xl mx-auto mb-20">
        <!-- Title -->
        <h1 class="h1 text-center mb-4">
            {{ page.title }}, {{ page.tag }} Model
        </h1>
    </header>

    <!-- Article content -->
    <div class="lg:flex lg:justify-between" data-sticky-container>


        <!-- Main content -->
        <div>

        <!-- Article meta -->
        <div class="flex items-center mb-6">
            <div class="flex flex-shrink-0 mr-3">
            <!-- <a class="relative" href="#0">
                <span class="absolute inset-0 -m-px" aria-hidden="true"><span class="absolute inset-0 -m-px bg-white rounded-full"></span></span>
                <img class="relative rounded-full" :src="require('@/images/news-author-04.jpg')" width="32" height="32" alt="Author 04" />
            </a>
            </div>
            <div>
            <span class="text-gray-600">By </span>
            <a class="font-medium hover:underline" href="#0">Marcus</a>
            <span class="text-gray-600"> · Jan 25, 2023</span> -->
            </div>
        </div>
        <hr class="w-16 h-px pt-px bg-gray-200 border-0 mb-6" />
        
        <!-- Article body -->
        <div class="text-lg text-gray-600" >
            <h4 class="h4 title">{{ page.title }} is a {{ page.tag }} Model:</h4>

            <div class="grid grid-cols-2 gap-2">
                <div>
                    <figure class="relative mt-4 h-0 pb-9/16 overflow-hidden translate-z-0 rounded">
                        <img class="absolute inset-0 w-full h-full object-cover transform scale-105 hover:-translate-y-1 transition duration-700 ease-out" 
                        :src="`${page.img_url}`" width="352" height="198" :alt="page.content" />
                    </figure>
                </div>
                <!-- ... -->
                <div class="mx-5 mt-5">
                        {{ page.content }}
                </div>
            </div>


            <!-- Related content -->
    <div class="mt-8">
      <h3 class="h3 mb-8">Related models ({{ relatedApps.length}})</h3>
      <a class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" :href="'/ai-tools/'+app.key" v-bind:key="app.id" v-for="app in relatedApps">
        <div>
          <div class="text-normal font-medium mb-1">{{ app.title }}</div>
          <!-- <div class="text-sm text-gray-600"
            v-html="g.contents[0].substring(0, 80)+'...'">
            
          </div> -->
        </div>
        <svg class="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </a>
      <hr class="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
    </div>
            
            
        </div>

        </div>

    </div>

    <!-- Article footer -->
    </article>
</div>

</div>
</div>
</section>
</template>

<script>

export default {

  name: 'Test',
  data(){
    return {
        listGlossary: [],
        sections: [
            "Introduction",
            "AI",
            "Related terms"
        ],
        page: null,
        apps: [],
        relatedApps: []
    }
  },
  mounted(){
        this.$store.dispatch("getModels").then((data) => {
            this.apps = data
            this.relatedApps = data
            
            var path = this.$route.path.split('/')
            path = path[path.length-1]
            
            this.page = this.apps.find(x => x.key == path)
            
            this.relatedApps = this.apps.filter(x => x.tag == this.page.tag)

            if(this.page != undefined){
                
                this.sections = [
                    "Introduction",
                    this.page.title,
                    "Related terms"
                ]
            }
            document.title = this.page.title + " - AI Tools - Metatext.AI"
            document.description = this.page.content
        })
  },
}
</script>
