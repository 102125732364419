<template>
  <section class="border-t border-gray-200">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 pb-12 md:pt-40 md:pb-20">

        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center pb-12">
          <h2 class="h2 mb-4" data-aos="zoom-y-out">
            Upgrade for professional usage
          </h2>
          <p class="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
            Not sure yet? Give it a try today!
          </p>
        </div>

        <!-- Pricing tables -->
        <div>

          <div class="max-w-sm md:max-w-2xl xl:max-w-none mx-auto grid gap-6 md:grid-cols-2 xl:grid-cols-3 xl:gap-6 items-start">

            <!-- Pricing table 1 -->
            <div class="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl" data-aos="zoom-y-out" data-aos-delay="450">
                <div class="mb-4">
                <div class="text-lg font-bold mb-1">Starter</div>
                <div class="inline-flex items-baseline mb-2" v-if="productStarterPricing[creditsStarter] == 0">
                  <span class="text-4xl font-bold">Free</span>
                  <span class="text-gray-600 pl-2"></span>
                </div>
                <div class="inline-flex items-baseline mb-2" v-else>
                  <span class="text-4xl font-bold">${{ productStarterPricing[creditsStarter] }}</span>
                  <span class="text-gray-600 pl-2">/month</span>
                </div>
                <div class="text-lg text-gray-800">Best for Individuals</div>
                <!-- <input id="steps-range" type="range" :min="3" :max="creditsStarterList.length-1" :step="1" v-model="creditsStarter" class="w-full h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer dark:bg-blue-700"> -->
              </div>
              
              <ul class="text-gray-600 -mb-2 flex-grow">
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>{{ creditsStarterList[creditsStarter] }} credits per month</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Up to 5 projects</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Up to 1,000 records per project</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Up to 10 model version per project</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Unlimited labels</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Public visibility</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Low GPU capacity</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Standard support</span>
                </li>
              </ul>
              <div class="border-t border-gray-200 pt-5 mt-6">
                <a class="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full" href="/book-intro-call" target="_blank">Try it</a>
              </div>
            </div>

            <!-- Pricing table 3 -->
            <div class="relative flex flex-col h-full py-5 px-6 rounded bg-blue-100 shadow-xl border-2 border-blue-500" data-aos="zoom-y-out" data-aos-delay="450">
                <div class="absolute top-0 right-0 mr-5 p-3 -mt-5 bg-yellow-500 rounded-full">
                <svg class="w-4 h-4 fill-current text-white" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.145 5.05l-4.316-.627L8.898.513c-.338-.684-1.456-.684-1.794 0l-1.93 3.91-4.317.627a1.002 1.002 0 00-.554 1.707l3.124 3.044-.737 4.3a1 1 0 001.45 1.053L8 13.125l3.862 2.03c.728.381 1.59-.234 1.45-1.054l-.736-4.299L15.7 6.758a1.003 1.003 0 00-.555-1.708z" />
                </svg>
              </div>
              <div class="mb-4">
                <div class="text-lg font-bold mb-1">Pro</div>
                <div class="inline-flex items-baseline mb-2">
                  <span class="text-3xl font-bold">$</span>
                  <span class="text-4xl font-bold">{{ productProPricing[credits] }}</span>
                  <span class="text-gray-600 pl-2">/month</span>
                </div>
                <div class="text-lg text-gray-800">Best for Businesses</div>
                <!-- <label for="steps-range" class="block mb-2 text-md font-medium text-gray-900 dark:text-white">Calculator For Pro Plan: {{credits}} credits per month, ${{ productProPricing[credits] }} per month, billed monthly</label> -->
                <input id="steps-range" type="range" :min="0" :max="creditsList.length-1" :step="1" v-model="credits" class="w-full h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer dark:bg-blue-700">
              </div>
              
              <ul class="text-gray-600 -mb-2 flex-grow">
                <b class="text-gray-800">Everything in Starter, Plus</b>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>{{ creditsList[credits] }} credits per month</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Unlimited projects</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Up to 10k records per project</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Unlimited model version per project</span>
                </li>
                <!-- <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>$0,001 per additional credit</span>
                </li> -->
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Visibility control</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Superior GPU capacity</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Priority support</span>
                </li>
              </ul>
              <div class="border-t border-gray-200 pt-5 mt-6">
                <a class="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full" href="/book-intro-call" target="_blank">
                    Try PRO
                </a>
                
              </div>
            </div>

            <!-- Pricing table 4 -->
            <div class="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl" data-aos="zoom-y-out" data-aos-delay="450">
              <div class="mb-4">
                <div class="text-lg font-bold mb-1">Enterprise</div>
                <div class="inline-flex items-baseline mb-2">
                  <span class="text-3xl font-bold">Custom</span>
                  <span class="text-4xl font-bold"></span>
                  <span class="text-gray-600 pl-2"></span>
                </div>
                <div class="text-lg text-gray-800">Best for Large Enterprises</div>
              </div>
              <ul class="text-gray-600 -mb-2 flex-grow">
                <b class="text-gray-800">Everything in PRO, Plus</b>
                <li class="flex items-center mb-2">
                  <!-- <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg> -->
                  <span>Custom pricing and resources</span>
                </li>
                <li class="flex items-center mb-2">
                  <!-- <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg> -->
                  <span>On premise</span>
                </li>
                <li class="flex items-center mb-2">
                  <!-- <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg> -->
                  <span>Dedicated AI engineer at your disposal</span>
                </li>
               
                <li class="flex items-center mb-2">
                  <!-- <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg> -->
                  <span>Learn how we can scale with your business</span>
                </li>
                <!-- <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Unlimited model trainings</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Unlimited predictions</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Custom predictions</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Dedicated end2end support</span>
                </li> -->
              </ul>
              <div class="border-t border-gray-200 pt-5 mt-6">
                <a class="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full" href="/book-intro-call" target="_blank">Book a sales call</a>
              </div>
            </div>

          </div>

        </div>

        <br><br>
        <!-- <label for="steps-range" class="block mb-2 text-md font-medium text-gray-900 dark:text-white">Calculator For Pro Plan: {{credits}} credits per month, ${{ productProPricing[credits] }} per month, billed monthly</label>
        <input id="steps-range" type="range"  :min="40000" :max="200000" :step="40000" v-model="credits" class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700">
        <br><br> -->

        <div class="max-w-3xl mx-auto text-center pb-12">
            <h4 class="h4 mb-4" data-aos="zoom-y-out">
                What are credits?
          </h4>
          <p class="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
                Credits are the unit value on Metatext platform and can be used for training a model or making predictions (or inferences) by API or by our inference preview. <br><br>
                

            </p>


<div class="relative overflow-x-auto">
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                    Action
                </th>
                <th scope="col" class="px-6 py-3">
                    Credits required
                </th>
                <th scope="col" class="px-6 py-3">
                    Description
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Training a model (small size) with 1200 training records
                </th>
                <td class="px-6 py-4">
                    1200 credits
                </td>
                <td class="px-6 py-4">
                    A model trained on 1200 records consumes 1200 credits
                </td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Making predictions for 100 texts (emails, chats, etc)
                </th>
                <td class="px-6 py-4">
                    100 credits
                </td>
                <td class="px-6 py-4">
                    100 texts predicted consumes 100 credits
                </td>
            </tr>
            
        </tbody>
    </table>
</div>
<!-- 
<br><br>


            <h4 class="h4 mb-4" data-aos="zoom-y-out">What can I spend my credits on?</h4>
            <p class="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
                A credit is equal to a model trained with 1 record or 1 text predicted.
            </p>
            <p class="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
                <b>For example, with a 2000 credit package on free plan you can:</b>
                <br><br>
                1. Train a project with 1500 records (or texts). This will cost 1500 credits and leave you with a remaining balance of 500 credits.
                <br><br>
                2. Test 10 texts using the Inference feature. This will cost 10 credits and the remaining balance will be 490.
                <br><br>
                3. Each text sent to the Inference endpoint will cost 1 credit.
                <br><br>
                4. Each record sent to the Training will cost 1 credit.
            </p> -->
        </div><br><br>
        <!-- Section header -->
        <!-- <div class="max-w-3xl mx-auto text-center pb-12">
          <h4 class="h4 mb-4" data-aos="zoom-y-out">
            Discount for universities
          </h4>
          <p class="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">
            We do offer discounts for academic research (available for Pro or Enterprise plan), please contact us to learn more. Write a line at hi@metatext.io
          </p>
        </div> -->

      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: 'PricingTables',
  data: function () {
    return {
      value: true,
      credits: 2,
      creditsStarter: 2,
      creditsList: [40000, 80000, 160000],
      creditsStarterList: [500, 2000, 5000],
      productProPricing: {
            0:  105,
            1:  195,
            2: 295,
        }, 
    productStarterPricing: {
        0: 0,
        1: 18,
        2: 35
    }, 
    }
  },
}
</script>