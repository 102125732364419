<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <section>
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <div class="pt-32 pb-12 md:pt-40 md:pb-20">

            <!-- Main content -->
            <div class="md:flex md:justify-between" data-sticky-container>

              <!-- Sidebar -->
              <aside class="relative my-12 md:my-0 md:w-64 md:mr-12 lg:mr-20 md:flex-shrink-0">
                <div data-sticky data-margin-top="100" data-sticky-for="768" data-sticky-wrap>

                  <!-- Search form -->
                  <!-- <form class="mb-4 pb-4 border-b border-gray-200">
                    <div class="flex flex-wrap">
                      <div class="w-full">
                        <label class="block text-sm sr-only" for="search">Search</label>
                        <div class="relative flex items-center">
                          <input id="search" type="search" class="form-input w-full text-gray-800 px-3 py-2 pl-10" placeholder="Search the docs" />
                          <button type="submit" class="absolute inset-0 right-auto" aria-label="Search">
                            <svg class="w-4 h-4 fill-current text-gray-400 mx-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zM15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form> -->

                  <button class="flex items-center justify-between text-lg font-medium text-gray-900 w-full my-4 md:hidden" @click="sidebarNavOpen = !sidebarNavOpen">
                    <span>Docs navigation</span>
                    <svg class="w-3 h-3 fill-current text-blue-600 flex-shrink-0 ml-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <rect y="7" width="16" height="2" rx="1" class="transform origin-center transition duration-200 ease-out" :class="{'rotate-180': sidebarNavOpen}" />
                      <rect y="7" width="16" height="2" rx="1" class="transform origin-center rotate-90 transition duration-200 ease-out" :class="{'rotate-180': sidebarNavOpen}" />
                    </svg>
                  </button>

                  <!-- Docs nav -->
                  <nav class="md:block" :class="{ 'hidden' : !sidebarNavOpen }">
                    <ul class="font-medium -my-2">
                      <!-- 1st level -->
                      <li class="py-2">
                        <a class="flex items-center hover:underline" href="/documentation/intro">
                          <div class="flex items-center flex-grow">
                            <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 3.294L1.4 1.035C1.1.847.7.941.4 1.13c-.2.189-.4.471-.4.753v10.353c0 .377.2.753.6.847L7 15.718V3.294zM15.6 1.13c-.3-.189-.6-.189-.9-.095L9 3.295v12.423l6.4-2.542c.4-.188.6-.47.6-.847V1.882c0-.282-.2-.564-.4-.753z" />
                            </svg>
                            <span>Introduction</span>
                            
                          </div>
                        </a>
                        
                        <!-- 2nd level -->
                        <ul class="font-normal -mb-1 mt-1 ml-2 pl-5 border-l border-gray-300" :class="{ 'hidden': !sidebarLinkOpen }">
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="/documentation/intro#intro">Metatext platform</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="/documentation/intro#nlp">AI and NLP basic concepts</a>
                          </li>
                          <!-- <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="/documentation/intro#ml">Machine Learning vs Deep Learning</a>
                          </li> -->
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="/documentation/intro#llm">Large Language Models</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="/documentation/intro#mlops">MLOps</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="/documentation/intro#drift">Concept Drift</a>
                          </li>
                        </ul>
                      </li>

                      <!-- <li class="py-2">
                        <a class="flex items-center hover:underline" href="#intro">
                          <div class="flex items-center flex-grow">
                            <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 3.294L1.4 1.035C1.1.847.7.941.4 1.13c-.2.189-.4.471-.4.753v10.353c0 .377.2.753.6.847L7 15.718V3.294zM15.6 1.13c-.3-.189-.6-.189-.9-.095L9 3.295v12.423l6.4-2.542c.4-.188.6-.47.6-.847V1.882c0-.282-.2-.564-.4-.753z" />
                            </svg>
                            <span>Use cases</span>
                          </div>
                        </a>
                        <ul class="font-normal -mb-1 mt-1 ml-2 pl-5 border-l border-gray-300" :class="{ 'hidden': !sidebarLinkOpen }">
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#intro">Customer support</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#upload">User feedback</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#upload">Custom</a>
                          </li>
                        </ul>
                      </li> -->

                      
                      <!-- Getting started -->
                      <!-- <li class="py-2">
                        <a class="flex items-center hover:underline" href="/documentation/started">
                          <div class="flex items-center flex-grow">
                            <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M15 4H4c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1zM12 10H1c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1zM15 16H4c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1z" />
                            </svg>
                            <span>Getting started</span>
                          </div>
                        </a>
                        <ul class="font-normal -mb-1 mt-1 ml-2 pl-5 border-l border-gray-300" :class="{ 'hidden': !sidebarLinkOpen }">
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#installation">AI projects</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#quick">Upload data</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#quick">Data labeling</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#quick">Model training</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#quick">Integrations</a>
                          </li>
                        </ul>
                      </li> -->

                      <!-- 
                      <li class="py-2">
                        <a class="flex items-center hover:underline" href="#introduction">
                          <div class="flex items-center flex-grow">
                            <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 9H1a1 1 0 01-1-1V1a1 1 0 011-1h5a1 1 0 011 1v7a1 1 0 01-1 1zM6 16H1a1 1 0 01-1-1v-3a1 1 0 011-1h5a1 1 0 011 1v3a1 1 0 01-1 1zM15 6h-5a1 1 0 01-1-1V1a1 1 0 011-1h5a1 1 0 011 1v4a1 1 0 01-1 1zM15 16h-5a1 1 0 01-1-1V9a1 1 0 011-1h5a1 1 0 011 1v6a1 1 0 01-1 1z" />
                            </svg>
                            <span>Datasets</span>
                          </div>
                        </a>
                        <ul class="font-normal -mb-1 mt-1 ml-2 pl-5 border-l border-gray-300" :class="{ 'hidden': !sidebarLinkOpen }">
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#installation">Upload data</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#quick">Data labeling</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#folder">Label analysis</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#folder">Label Issues</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#folder">Get more data</a>
                          </li>
                        </ul>
                      </li>
                      <li class="py-2">
                        <a class="flex items-center hover:underline" href="#introduction">
                          <div class="flex items-center flex-grow">
                            <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 15.414l-2.5-3V1a1 1 0 011-1h3a1 1 0 011 1v11.414l-2.5 3zM7 0H1C.4 0 0 .4 0 1v2h3v2H0v2h3v2H0v2h3v2H0v2c0 .6.4 1 1 1h6c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1z" />
                            </svg>
                            <span>Models</span>
                          </div>
                        </a>
                        
                        <ul class="font-normal -mb-1 mt-1 ml-2 pl-5 border-l border-gray-300" :class="{ 'hidden': !sidebarLinkOpen }">
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#installation">Training</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#quick">Evaluation</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#folder">Metrics</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#folder">Error Analysis</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#folder">Deployment</a>
                          </li>
                        </ul>
                      </li> -->
                      <li class="py-2">
                        <a class="flex items-center hover:underline" href="/documentation/api" active>
                          <div class="flex items-center flex-grow">
                            <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="4.5" cy="4.5" r="4.5" />
                              <circle cx="12" cy="13" r="3" />
                              <circle cx="14.5" cy="5.5" r="1.5" />
                            </svg>
                            <span>Metatext APIs</span>
                          </div>
                        </a>
                        <ul class="font-normal -mb-1 mt-1 ml-2 pl-5 border-l border-gray-300" :class="{ 'hidden': !sidebarLinkOpen }">
                          
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="/documentation/api#auth">Authentication</a>
                          </li>
                          
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="/documentation/inference-api-v1">Inference v1</a>
                          </li>

                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="/documentation/inference-api-v1.1">Inference v1.1 (Multi-label support)</a>
                          </li>

                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="/documentation/records-api">Records v1</a>
                          </li>
                          <ul class="font-normal mb-1 mt-1 ml-2 pl-2 border-gray-300" :class="{ 'hidden': !sidebarLinkOpen }">
                            
                            <li class="py-1">
                                <a class="text-gray-600 hover:underline" href="/documentation/records-api#post">Create</a>
                            </li>
                            <li class="py-1">
                                <a class="text-gray-600 hover:underline" href="/documentation/records-api#get">Retrieve</a>
                            </li>
                            <li class="py-1">
                                <a class="text-gray-600 hover:underline" href="/documentation/records-api#put">Update</a>
                            </li>
                            <li class="py-1">
                                <a class="text-gray-600 hover:underline" href="/documentation/records-api#delete">Delete</a>
                            </li>
                          </ul>
                          
                          <!-- <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#batch-inference-api">Batch inference API</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#model-hub">Model Hub</a>
                          </li> -->
                        </ul>
                      </li>
                      <!-- <li class="py-2">
                        <a class="flex items-center hover:underline" href="#introduction">
                          <div class="flex items-center flex-grow">
                            <svg class="w-4 h-4 fill-current text-blue-600 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.5 15.414l-2.5-3V1a1 1 0 011-1h3a1 1 0 011 1v11.414l-2.5 3zM7 0H1C.4 0 0 .4 0 1v2h3v2H0v2h3v2H0v2h3v2H0v2c0 .6.4 1 1 1h6c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1z" />
                            </svg>
                            <span>Pre-build models</span>
                          </div>
                        </a>
                        
                        <ul class="font-normal -mb-1 mt-1 ml-2 pl-5 border-l border-gray-300" :class="{ 'hidden': !sidebarLinkOpen }">
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#installation">Text completion</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#quick">Classification</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#folder">Extractors</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#folder">Summarization</a>
                          </li>
                          <li class="py-1">
                            <a class="text-gray-600 hover:underline" href="#folder">Raw NLP models</a>
                          </li>
                        </ul>
                      </li> -->
                    </ul>
                  </nav>

                </div>
              </aside>

              <!-- Page container -->
              <DocumentationAPIs v-if="docId == 'api'"/>
              <DocumentationInferenceAPI v-else-if="docId == 'inference-api-v1'"/>
              <DocumentationInferenceAPIv11 v-else-if="docId == 'inference-api-v1.1'"/>
              <DocumentationStarted v-else-if="docId == 'started'"/>
              <DocumentationRecordAPI v-else-if="docId == 'records-api'"/>
              <DocumentationIntro v-else/>

            </div>

          </div>
        </div>
      </section>

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import DocumentationIntro from './../partials/Documentation/DocumentationIntro.vue'
import DocumentationAPIs from './../partials/Documentation/DocumentationAPIs.vue'
import DocumentationInferenceAPI from './../partials/Documentation/DocumentationInferenceAPI.vue'
import DocumentationInferenceAPIv11 from './../partials/Documentation/DocumentationInferenceAPIv11.vue'
import DocumentationRecordAPI from './../partials/Documentation/DocumentationRecordAPI.vue'
import DocumentationStarted from './../partials/Documentation/DocumentationStarted.vue'
import Footer from './../partials/Footer.vue'

export default {
  props: ["docId"],
  name: 'Documentation',
  components: {
    Header,
    DocumentationAPIs,
    DocumentationInferenceAPI,
    DocumentationInferenceAPIv11,
    DocumentationRecordAPI,
    DocumentationStarted,
    DocumentationIntro,
    Footer,
  },
  data: function () {
    return {
      sidebarNavOpen: false,
      sidebarLinkOpen: true
    }
  } 
}
</script>
