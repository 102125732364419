<template>
    <div class="flex flex-col min-h-screen overflow-hidden">
  
      <!-- Site header -->
      <Header />
  
      <!-- Page content -->
      <main class="flex-grow">
  
        <!-- Page sections -->
        <PageList />
  
  
  
      </main>
  
      <!-- Site footer -->
      <Footer />
  
    </div>
  </template>
  
  <script>
  import Header from './../partials/Header.vue'
  import PageList from './../partials/contents/models/List.vue'
  import Footer from './../partials/Footer.vue'
  
  export default {
    name: 'models',
    components: {
      Header,
      PageList,
      Footer,
    }
  };
  </script>
  