<template>
  <div class="flex bg-black text-white flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
      <HeroHome />

      <Demo />

      
      
<!--       
      <Demo />
      <FeaturesHome />
      <ExploreCases />
      <FeaturesWorld />
      <TestimonialsCarousel />
      <UseCases />
      <PricingTables />
      <Credentials /> -->
      
      
      
      <!-- <Faqs />
      <Cta /> -->

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>


import HeroHome from './../partials/safety/HeroHome.vue'
import Footer from './../partials/safety/Footer.vue'
import Demo from './../partials/safety/Demo.vue'
export default {
  name: 'Home',
  components: {
    HeroHome,
    Footer,
    Demo
  },
  created(){
      document.title = this.$i18n.t(`title`)
  },
}
</script>
