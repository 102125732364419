<template>
    <div class="flex flex-col min-h-screen overflow-hidden">
  
      <!-- Site header -->
      <Header />
  
      <!-- Page content -->
      <main class="flex-grow">
  
        <!-- Page sections -->
        <ContentSingle />
  
      </main>
  
      <!-- Site footer -->
      <Footer />
  
    </div>
  </template>
  
  <script>
  import Header from './../partials/Header.vue'
  import ContentSingle from './../partials/contents/models/Single.vue'
  import Footer from './../partials/Footer.vue'
  
  export default {
    name: 'models',
    components: {
      Header,
      ContentSingle,
      Footer,
    },
  }
  </script>
  